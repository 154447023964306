import { useMutation, useQuery } from '@tanstack/react-query';

import { ProjectAndSpacesDto } from '@/types';

import axios from '@/axios';

const getProjectsAndSpaces = async (ownershipType: string) => {
  const { data } = await axios.get<ProjectAndSpacesDto[]>(
    `/api/v2/Project/GetProjectsAndSpaces?ownershipType=${ownershipType}`
  );

  return data.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
};

export const useGetProjectsAndSpaces = (ownershipType: string = 'Accessible') => {
  return useQuery<ProjectAndSpacesDto[]>(
    ['projects-and-spaces', ownershipType],
    () => getProjectsAndSpaces(ownershipType),
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const useFetchProjectsAndSpaces = (ownershipType: string = 'Accessible') => {
  return useMutation(async () => await getProjectsAndSpaces(ownershipType));
};
