import dayjs from 'dayjs';

import { Stack } from '@mui/material';

import { NameMarkerRow } from '@/components/Shared/NameMarker';
import { TrblPopup, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { Text } from '@/components/Shared/Text';

import { Info } from '@/types';

interface InfoProjectPopupProps {
  info: Info;
  onClose?: () => void;
}
export const InfoProjectPopup = ({ info, onClose }: InfoProjectPopupProps) => {
  return (
    <TrblPopup aria-labelledby={'Project details'} open={true} onClose={onClose}>
      <TrblPopupTitle onClose={onClose} small>
        Project details
      </TrblPopupTitle>
      <TrblPopupContent>
        <Stack gap="12px" marginBottom="8px">
          <div>
            <Text type="semibold-12px">Name: </Text>
            <Text type="regular-12px">{info.name}</Text>
          </div>
          <div>
            <Text type="semibold-12px">Created: </Text>
            <Text type="regular-12px">
              {info.createdAt ? (
                dayjs(info.createdAt).format('DD MMM YYYY, HH:mm')
              ) : (
                <span style={{ color: '#adadad' }}>Date not available</span>
              )}
            </Text>
          </div>
          <div>
            <Text type="semibold-12px">Created by: </Text>
            <Text type="regular-12px">{info.createdBy}</Text>
          </div>
          {info.updatedAt && (
            <div>
              <Text type="semibold-12px">Last modified: </Text>
              <Text type="regular-12px">
                {info.updatedAt ? (
                  dayjs(info.updatedAt).format('DD MMM YYYY, HH:mm')
                ) : (
                  <span style={{ color: '#adadad' }}>Date not available</span>
                )}
              </Text>
            </div>
          )}
          {info.updatedBy && (
            <div>
              <Text type="semibold-12px">Modified by: </Text>
              <Text type="regular-12px">{info.updatedBy}</Text>
            </div>
          )}

          {info.projectUsers && info.projectUsers.length > 0 && (
            <Stack direction="row" alignItems="center" gap="12px">
              <Text type="semibold-12px">Shared with: </Text>

              <NameMarkerRow projectUsers={info.projectUsers} size="small" background="#262626" />
            </Stack>
          )}
          <div>
            <Text type="semibold-12px">Description: </Text>
            <Text type="regular-12px">
              {info.description ? info.description : <span style={{ color: '#adadad' }}>none</span>}
            </Text>
          </div>
          <div>
            <Text type="semibold-12px">Spaces: </Text>
            <Text type="regular-12px">{info.elementCount}</Text>
          </div>
        </Stack>
      </TrblPopupContent>
    </TrblPopup>
  );
};
