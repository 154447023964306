export const NOISE_LEVELS_DEFAULT = ['15', '20', '25', '30', '35', '40', '45', '50', '55', '60', '65', '70'];

export const FREQUENCIES_DEFAULT = ['63', '125', '250', '500', '1000', '2000', '4000', '8000'];

export const FREQUENCIES_DEFAULT_FOR_SPL = [
  '63',
  '125',
  '250',
  '500',
  '1000',
  '2000',
  '4000',
  '8000',
  'Total dBA',
  'Total dBC',
  'Total dBZ',
];

export enum DecimalPlaces {
  '.1f' = 1,
  '.2f' = 2,
  '.3f' = 3,
}
