import { FC } from 'react';

type ShareDisabledInformation = {
  type: string;
};

export const ShareDisabledInformation: FC<ShareDisabledInformation> = ({ type }) => (
  <div>
    <p>
      Upgrade to a Team plan is needed to share {type}.
      <br />
      Visit{' '}
      <a target="_blank" style={{ textDecoration: 'underline' }} href="https://treble.tech/pricing">
        <b>treble.tech/pricing</b>
      </a>{' '}
      for more information.
    </p>
  </div>
);
