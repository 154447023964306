import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblHomeIcon: FC<IconProps> = ({ fill = '#DADADA', width = '14', height = '14' }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.829 6.5877L7.41231 0.171036C7.18452 -0.0566866 6.81527 -0.0566866 6.58748 0.171036L0.170809 6.5877C0.00402953 6.75453 -0.0458536 7.00539 0.0444137 7.22333C0.134681 7.44128 0.347328 7.5834 0.583226 7.58345H1.31239C1.39293 7.58345 1.45823 7.64874 1.45823 7.72929V13.4168C1.45823 13.739 1.71939 14.0001 2.04156 14.0001H5.39573C5.47627 14.0001 5.54156 13.9348 5.54156 13.8543V11.0835C5.54156 10.278 6.19448 9.62512 6.99989 9.62512C7.80531 9.62512 8.45823 10.278 8.45823 11.0835V13.8543C8.45823 13.9348 8.52352 14.0001 8.60406 14.0001H11.9582C12.2804 14.0001 12.5416 13.739 12.5416 13.4168V7.72929C12.5416 7.64874 12.6068 7.58345 12.6874 7.58345H13.4166C13.6525 7.5834 13.8651 7.44128 13.9554 7.22333C14.0456 7.00539 13.9958 6.75453 13.829 6.5877Z"
      fill={fill}
    />
  </svg>
);
