import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblExpandFromCornerIcon: FC<IconProps> = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.6875 13.3113L5.4375 18.5613" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.5625 9.37378V5.43628H14.625" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.4375 14.6238V18.5613H9.375" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.5625 5.43628L13.3125 10.6863" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
