import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblCollapseFromCornerIcon: FC<IconProps> = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5625 1.43628L9.3125 6.68628" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.6875 13.2488V9.31128H2.75" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.3125 2.74878V6.68628H13.25" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.6875 9.31128L1.4375 14.5613" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
