import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblSpaceIcon: FC<IconProps> = ({ fill = '#DADADA', width = '14', height = '14' }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.10449 5.35425C5.10449 5.21618 5.21642 5.10425 5.35449 5.10425H8.64616C8.78423 5.10425 8.89616 5.21618 8.89616 5.35425V8.64591C8.89616 8.78399 8.78423 8.89592 8.64616 8.89592H5.35449C5.21642 8.89592 5.10449 8.78399 5.10449 8.64592V5.35425Z"
      fill={fill}
    />
    <path
      d="M4.08325 4.22933C4.1638 4.22933 4.22909 4.16404 4.22909 4.0835V1.33775C4.22919 1.27868 4.19366 1.22539 4.1391 1.20277C4.08454 1.18015 4.02172 1.19268 3.98 1.2345L1.23425 3.98025C1.19244 4.02196 1.17991 4.08478 1.20253 4.13934C1.22514 4.19391 1.27844 4.22944 1.3375 4.22933H4.08325Z"
      fill={fill}
    />
    <path
      d="M9.91732 9.771C9.83678 9.771 9.77148 9.83629 9.77148 9.91683V12.6626C9.77138 12.7216 9.80691 12.7749 9.86147 12.7976C9.91604 12.8202 9.97886 12.8076 10.0206 12.7658L12.7663 10.0201C12.8081 9.97837 12.8207 9.91555 12.798 9.86099C12.7754 9.80642 12.7221 9.77089 12.6631 9.771H9.91732Z"
      fill={fill}
    />
    <path
      d="M4.18691 9.19516C4.21419 9.16773 4.2295 9.13061 4.22949 9.09191V5.25008C4.22949 5.16954 4.1642 5.10425 4.08366 5.10425H0.875326C0.794784 5.10425 0.729492 5.16954 0.729492 5.25008V12.3002C0.729387 12.3593 0.764919 12.4126 0.819482 12.4352C0.874045 12.4578 0.936863 12.4453 0.978576 12.4035L4.18691 9.19516Z"
      fill={fill}
    />
    <path
      d="M8.89581 9.91683C8.89581 9.83629 8.83052 9.771 8.74998 9.771H4.90814C4.86945 9.77099 4.83233 9.7863 4.80489 9.81358L1.59656 13.0219C1.55482 13.0635 1.54225 13.1262 1.56472 13.1807C1.58719 13.2352 1.64027 13.2709 1.69923 13.271H8.74998C8.83052 13.271 8.89581 13.2057 8.89581 13.1252V9.91683Z"
      fill={fill}
    />
    <path
      d="M9.81407 4.80489C9.78679 4.83233 9.77148 4.86945 9.77148 4.90814V8.74998C9.77148 8.83052 9.83678 8.89581 9.91732 8.89581H13.1257C13.2062 8.89581 13.2715 8.83052 13.2715 8.74998V1.69981C13.2716 1.64074 13.2361 1.58745 13.1815 1.56483C13.1269 1.54221 13.0641 1.55474 13.0224 1.59656L9.81407 4.80489Z"
      fill={fill}
    />
    <path
      d="M5.10449 4.08341C5.10449 4.16396 5.16978 4.22925 5.25033 4.22925H9.09216C9.13085 4.22925 9.16797 4.21394 9.19541 4.18666L12.4037 0.978332C12.4456 0.936619 12.4581 0.873801 12.4355 0.819238C12.4129 0.764675 12.3596 0.729143 12.3005 0.729248H5.25033C5.16978 0.729248 5.10449 0.79454 5.10449 0.875082V4.08341Z"
      fill={fill}
    />
  </svg>
);
