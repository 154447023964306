export const TrblSearchIcon = ({ fill = '#ADADAD', width = '13', height = '13' }) => (
  <svg width={width} height={height} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.27018 9.40123C9.31756 8.53112 10.2719 6.16602 9.40181 4.11864C8.53169 2.07126 6.16659 1.1169 4.11922 1.98702C2.07184 2.85713 1.11748 5.22223 1.98759 7.26961C2.85771 9.31699 5.2228 10.2713 7.27018 9.40123Z"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8.54199 8.54224L11.666 11.6667" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
