import { SyntheticEvent, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { Skeleton, Stack } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { HighlightedText } from '@/components/Shared/HighlightedText';
import { TrblModelsIcon, TrblSimulationIcon } from '@/components/Icons';
import defaultPlaceholderImageUrl from './images/default-placeholder.png';
import { SpaceActionsMenu } from './SpaceActionsMenu';

import { SpaceDashboardDto } from '@/types';

import classes from './styles.module.scss';

interface SpaceCardProps {
  space: SpaceDashboardDto;
  tabIndex?: number;
  projectSearchString?: string;
  onClick?: () => void;
  onUpdate?: () => void;
  selected?: boolean;
  small?: boolean;
  modified?: string;
}

export const SpaceCard = ({
  space,
  tabIndex = 0,
  projectSearchString = '',
  onClick,
  onUpdate,
  selected,
  small,
  modified,
}: SpaceCardProps) => {
  const navigate = useNavigate();
  const cardRef = useRef<HTMLAnchorElement>(null);

  const [imgLoaded, setImgLoaded] = useState(false);

  const handleClickSpace = (e: SyntheticEvent) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  const handleDoubleClickSpace = () => {
    navigate(getEditorUrl());
  };

  const getEditorUrl = () => {
    if (space.latestSimulation.simulationId) {
      return `/editor?mid=${space.latestSimulation.modelId}&sid=${space.latestSimulation.simulationId}`;
    } else {
      return `/editor?mid=${space.latestSimulation.modelId}`;
    }
  };
  const isCardSearched =
    projectSearchString.length > 0 && space.name.toLowerCase()?.includes(projectSearchString.toLowerCase());

  return (
    <Link
      ref={cardRef}
      className={`${classes.space_card} ${selected ? classes.selected : ''} ${isCardSearched ? classes.searched : ''} ${
        small ? classes.small : ''
      }`}
      to={getEditorUrl()}
      onClick={handleClickSpace}
      onDoubleClick={handleDoubleClickSpace}
      tabIndex={tabIndex}>
      <div className={classes.space_card_item}>
        <div className={classes.space_thumbnail}>
          {space.modelCount > 1 && imgLoaded && (
            <>
              <div className={classes.space_thumbnail_stack} /> <div className={classes.space_thumbnail_stack} />
            </>
          )}
          <div>
            <img
              src={space.latestSimulation.modelThumbnailUrl || defaultPlaceholderImageUrl}
              style={{ opacity: imgLoaded ? '1' : '0' }}
              onLoad={() => {
                setImgLoaded(true);
              }}
              alt="space thumbnail"></img>
          </div>
        </div>
      </div>

      {!imgLoaded ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{
            width: '100%',
            height: small ? '50px' : '60px',
            margin: small ? '0px 0 0px -65px' : '10px 0 10px -65px',
            opacity: 0.15,
          }}
        />
      ) : (
        <>
          <div className={classes.space_card_item}>
            <TrblTooltip title={space.name.length > 45 ? space.name : ''}>
              <p className={classes.space_name}>
                <HighlightedText text={space.name} highlight={projectSearchString} highlightColor="#00f5ba" />
                {small && (
                  <div className={classes.modified_tag}>
                    {modified ?? 'Modified ' + dayjs(space.updatedAt).format('DD MMM, HH:mm')}
                  </div>
                )}
              </p>
            </TrblTooltip>
          </div>
          <div className={classes.space_card_item_last}>
            <div className={classes.models_tag}>
              <TrblTooltip title="Geometry iterations">
                <Stack flexDirection="row" alignItems="center" gap="8px">
                  <TrblModelsIcon fill="#999999" />
                  {space.modelCount}
                </Stack>
              </TrblTooltip>
              <TrblTooltip title="Simulations">
                <Stack flexDirection="row" alignItems="center" gap="8px">
                  <TrblSimulationIcon fill="#999999" />
                  {space.simulationCount}
                </Stack>
              </TrblTooltip>
            </div>
            {!small && (
              <div className={classes.modified_tag}>
                {modified ?? dayjs(space.updatedAt).format('DD MMM YYYY, HH:mm')}
              </div>
            )}
          </div>
          {!small && <SpaceActionsMenu space={space} classNames={classes.space_menu} onUpdate={onUpdate} />}
        </>
      )}
    </Link>
  );
};
