import { SyntheticEvent, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import { useBaseContext } from '@/context/BaseContext';

import {
  TrblAuralizeIcon,
  TrblFileIcon,
  TrblFolderIcon,
  TrblFolderSharedIcon,
  TrblHomeIcon,
  TrblLearnIcon,
  TrblQuickGuideIcon,
  TrblResultsIcon,
} from '@/components/Icons';

import { useGetProjectById } from '@/hooks';

import styles from './styles.module.scss';

export const Navpanel = () => {
  const {
    state: { userInfo },
  } = useBaseContext();
  const location = useLocation();
  const params = useParams();

  const [projectId, setProjectId] = useState('');
  const [projectType, setProjectType] = useState('');
  const { data: project } = useGetProjectById(projectId || '');

  useEffect(() => {
    // if a project url has been loaded and the previous page was not My projects or Shared with me
    // then we setProjectId to fetch project information to set the projectType
    if (
      location.pathname.includes('/project/') &&
      !checkPrevLocation('/new/myprojects') &&
      !checkPrevLocation('/new/sharedprojects')
    ) {
      setProjectId(params.id as string);
    }
  }, [location.pathname]);

  useEffect(() => {
    // setProjectType based on the createdBy of the project
    if (location.pathname.includes('/project/') && userInfo && project) {
      if (userInfo.id == project.createdBy) {
        setProjectType('myProjects');
      } else {
        setProjectType('sharedWithMe');
      }
    }
  }, [project]);

  const checkPrevLocation = (path: string) => {
    if (location.state && location.state.prevLocation == path) {
      return true;
    }
    return false;
  };

  const openQuickStartGuideWindow = (e: SyntheticEvent) => {
    e.preventDefault();
    window.open(
      'https://docs.treble.tech/user-guide/getting-started/quick_start_guide',
      'mywindow',
      'width=600,height=' + window.screen.height
    );
  };

  return (
    <div className={styles['navpanel']}>
      <div className={styles['navpanel-section']}>
        <Link
          to="/new"
          className={`${styles['navpanel-row']} ${location.pathname == '/new' ? styles['selected'] : ''}`}>
          <TrblHomeIcon /> Home
        </Link>
        <Link
          to="/new/myprojects"
          className={`${styles['navpanel-row']} ${
            location.pathname == '/new/myprojects' ||
            checkPrevLocation('/new/myprojects') ||
            projectType == 'myProjects'
              ? styles['selected']
              : ''
          }`}>
          <TrblFolderIcon /> My projects
        </Link>
        <Link
          to="/new/sharedprojects"
          className={`${styles['navpanel-row']} ${
            location.pathname == '/new/sharedprojects' ||
            checkPrevLocation('/new/sharedprojects') ||
            projectType == 'sharedWithMe'
              ? styles['selected']
              : ''
          }`}>
          <TrblFolderSharedIcon /> Shared with me
        </Link>
        <Link
          to="/learn"
          className={`${styles['navpanel-row']} ${location.pathname == '/learn' ? styles['selected'] : ''}`}>
          <TrblLearnIcon /> Treble academy
        </Link>
      </div>
      <div className={styles['navpanel-divider']} />
      <div className={styles['navpanel-section']}>
        <Link
          to="/savedresults"
          className={`${styles['navpanel-row']} ${location.pathname == '/savedresults' ? styles['selected'] : ''}`}>
          <TrblResultsIcon fill="#dadada" /> Results
        </Link>
        <Link
          to="/savedauralizations"
          className={`${styles['navpanel-row']} ${
            location.pathname == '/savedauralizations' ? styles['selected'] : ''
          }`}>
          <TrblAuralizeIcon fill="#dadada" /> Auralizations
        </Link>
      </div>
      <div className={styles['navpanel-divider']} />
      <div className={styles['navpanel-section']}>
        <a href="https://docs.treble.tech/user-guide" target="_blank" className={styles['navpanel-row']}>
          <TrblFileIcon fill="#dadada" /> Documentation
        </a>
        <a
          href="https://docs.treble.tech/user-guide/getting-started/quick_start_guide"
          onClick={openQuickStartGuideWindow}
          className={styles['navpanel-row']}>
          <TrblQuickGuideIcon fill="#dadada" /> Quick start guide
        </a>
      </div>
    </div>
  );
};
