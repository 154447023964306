import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblFolderIcon: FC<IconProps> = ({ fill = '#DADADA', width = '14', height = '14' }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.846 2.62501H13.125C13.6082 2.62501 14 3.01676 14 3.50001V12.25C14 12.7333 13.6082 13.125 13.125 13.125H0.875C0.391751 13.125 0 12.7333 0 12.25V1.75001C0 1.26676 0.391751 0.875009 0.875 0.875009H5.25C5.58106 0.873535 5.88437 1.05977 6.03283 1.35568L6.58525 2.46401C6.6347 2.56269 6.73562 2.62501 6.846 2.62501ZM4.18313 4.375C4.18176 4.81499 4.17255 5.24384 4.13066 5.62048C4.08484 6.03241 3.99805 6.40359 3.82252 6.66038C3.61953 6.957 3.13929 7.2149 1.75 7.23502V8.67814C3.14036 8.67814 4.26399 8.31147 4.87831 7.41377C5.40987 6.63702 5.56947 5.61851 5.57349 4.375H4.18313ZM5.57349 11.5251C5.57487 11.0851 5.58408 10.6693 5.62597 10.2927C5.67178 9.88074 5.75858 9.50957 5.9341 9.25277C6.13709 8.95615 6.61734 8.69825 8.00663 8.67813L8.00663 7.23501C6.61626 7.23501 5.49263 7.58859 4.87831 8.48628C4.34675 9.26304 4.18716 10.2815 4.18313 11.5251L5.57349 11.5251Z"
      fill={fill}
    />
  </svg>
);
