import { ActionType, useResultsContext } from '@/components/Results/context/ResultsContext';

import { useArrowKeyPress } from '../ReflectogramResultsHeader/hooks';

export const useModalAnalysisKeyPressHandlers = (
  modalAnalysisFrequencyValues: Record<string, number[]> | null,
  minValue: number,
  maxValue: number
) => {
  const { dispatch, modalFrequencySelected, isPlayingModalAnalysis } = useResultsContext();

  const handleSelectPreviousIndex = () => {
    if (modalFrequencySelected - 1 >= minValue) {
      dispatch({
        type: ActionType.SET_MODAL_FREQUENCY_SELECTED,
        modalFrequencySelected: modalFrequencySelected - 1,
      });
      if (isPlayingModalAnalysis) {
        stopPlaying();
      }
    }
  };

  const handleSelectNextIndex = () => {
    if (modalFrequencySelected + 1 <= maxValue) {
      dispatch({
        type: ActionType.SET_MODAL_FREQUENCY_SELECTED,
        modalFrequencySelected: modalFrequencySelected + 1,
      });
      if (isPlayingModalAnalysis) {
        stopPlaying();
      }
    }
  };

  const stopPlaying = () => {
    dispatch({
      type: ActionType.SET_IS_PLAYING_MODAL_ANALYSIS,
      isPlayingModalAnalysis: false,
    });
  };

  useArrowKeyPress(
    modalAnalysisFrequencyValues && Object.keys(modalAnalysisFrequencyValues).length ? handleSelectPreviousIndex : null,
    modalAnalysisFrequencyValues && Object.keys(modalAnalysisFrequencyValues).length ? handleSelectNextIndex : null
  );

  return {
    handleSelectPreviousIndex,
    handleSelectNextIndex,
  };
};
