import { FC, SyntheticEvent } from 'react';

import { useBaseContext } from '@/context/BaseContext';

import { TrblTooltip } from '@/components/Shared';
import { TrblAddMemberIcon } from '@/components/Icons';
import { ShareDisabledInformation } from '../ShareDisabledInformation';

import styles from './styles.module.scss';

type ShareProjectButtonProps = {
  size?: string;
  onClick: (e: SyntheticEvent) => void;
};

export const ShareProjectButton: FC<ShareProjectButtonProps> = ({ size = '26', onClick }) => {
  const {
    state: { subscriptionInfo },
  } = useBaseContext();
  return (
    <button className={styles['members-button']} onClick={onClick} disabled={!subscriptionInfo?.hasAccessToShare}>
      <TrblTooltip
        title={!subscriptionInfo?.hasAccessToShare ? <ShareDisabledInformation type="projects" /> : 'Share project'}>
        <span>
          <TrblAddMemberIcon width={size} height={size} />
        </span>
      </TrblTooltip>
    </button>
  );
};
