import {
  DoubleSide,
  Float32BufferAttribute,
  Group,
  MathUtils,
  Mesh,
  PlaneGeometry,
  ShaderMaterial,
  Vector2,
  Vector3,
} from 'three';

// @ts-expect-error Could not find a declaration file for module '@/utils/ColorBar'.
import { ColorBar } from '@/utils/ColorBar';

import { GridReceiverPoint, GridReceiverResultsDto } from '@/types';

export const createSimpleHeatmap = (
  colorBar: ColorBar,
  result: GridReceiverResultsDto,
  points: GridReceiverPoint[],
  valuesChosen: number[],
  isVisible: boolean,
  isInterpolated: boolean,
  isPlayingModalAnalysis: boolean,
  targetValue: boolean = false
) => {
  if (valuesChosen.length) {
    const start = Date.now();

    const validPoints = points;
    const grUserInput = result.userInput;

    const group = new Group();
    group.name = 'simple_heatmap_' + result.id;
    group.userData.pointId = result.pointId;
    const squareGeometry = new PlaneGeometry(grUserInput.coarseness, grUserInput.coarseness);
    // @ts-expect-error Property 'array' does not exist on type 'BufferAttribute | InterleavedBufferAttribute | GLBufferAttribute'.
    const verts = squareGeometry.attributes.position.array;
    squareGeometry.setAttribute('alpha', new Float32BufferAttribute([1, 1, 1, 1, 1, 1], 1));
    let cols = [];
    let pressure, color;

    for (let i = 0; i < validPoints.length; i++) {
      pressure = valuesChosen[i];
      if (isFinite(pressure) && Math.abs(pressure) < 150) {
        color = colorBar.getColor(pressure);

        cols = [];
        for (let k = 0; k < verts.length; k += 3) {
          cols.push(color.r, color.g, color.b);
        }
        squareGeometry.setAttribute('color', new Float32BufferAttribute(cols, 3));

        const squareMaterial = new ShaderMaterial({
          uniforms: {
            // Here is how we define a float value
            u_time: { value: 0 },
            u_resolution: { value: new Vector2() },
          },
          // @ts-expect-error Object is possibly 'null'.
          vertexShader: document.getElementById('vertexShader').textContent,
          // @ts-expect-error Object is possibly 'null'
          fragmentShader: document.getElementById('fragmentShader').textContent,
          side: DoubleSide,
          transparent: false,
          visible: isVisible,
        });
        const square = new Mesh(squareGeometry.clone(), squareMaterial);
        square.userData.pressure = pressure;
        square.userData.index = i;
        square.userData.gridIndex = validPoints[i].gridIndex;
        square.name = 'heatSquare';

        square.position.set(validPoints[i].x, validPoints[i].y, validPoints[i].z);
        square.rotateOnWorldAxis(new Vector3(1, 0, 0), MathUtils.degToRad(grUserInput.roll));
        square.rotateOnWorldAxis(new Vector3(0, 1, 0), MathUtils.degToRad(-grUserInput.yaw));
        square.rotateOnWorldAxis(new Vector3(0, 0, 1), MathUtils.degToRad(grUserInput.pitch));

        if (!targetValue) group.add(square);
        // if targetValue heatmap then check if value is within min and max of colorBar
        else if (pressure <= colorBar.getMax() && pressure >= colorBar.getMin()) {
          group.add(square);
        }
      }
    }
    const end = Date.now();
    console.log(`createSimpleHeatmap Execution time: ${end - start} ms`);
    return group;
  }

  return null;
};
