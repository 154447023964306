import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ActionType, useAppContext } from '@/context/AppContext';
import { useBaseContext } from '@/context/BaseContext';

import { Box } from '@mui/material';

import { PrimaryButton } from '@/components/Shared/Buttons';
import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { FrequenciesTable } from '@/components/SourceDefinition/FrequenciesTable';
import { SourceDefinitionDetails } from '@/components/SourceDefinition/SourceDefinitionDetails';
import { TrblClfIcon, TrblReferenceCurveIcon } from '../Icons';
import { TrblToggle, TrblTooltip } from '../Shared';
import { BufferLoadingScreen } from '../Shared/BufferLoadingScreen';
import { FileUpload } from '../Shared/FileUpload';
import { ShareDisabledInformation } from '../Shared/ShareDisabledInformation';
import { SourceDefinitionFooter } from '../SourceDefinition/SourceDefinitionFooter';
import { SourceDefinitionPlots } from '../SourceDefinition/SourceDefinitionPlots';
import { SourceDefinitionWarning } from '../SourceDefinition/SourceDefinitionWarning';
import { TableDivider } from '../SourceDefinition/TableDivider';
import { SourceDefinitionInput } from './SourceDefinitionInput';

import {
  uploadFileToTreble,
  useGetSourceDefinitionById,
  useGetSourceDefinitionStatus,
  useStartSourceDefinitionImport,
} from '@/hooks';

import { SourceDefinition } from '@/types';

import styles from '@/components/SourceRecieverSettings/SourceSettings/styles.module.scss';

export const CreateSourceDefinition = ({ setShowPopup }: { setShowPopup: (show: boolean) => void }) => {
  const {
    dispatch,
    appState: { filteredSourceDefinitions },
  } = useAppContext();

  const [description, setDescription] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [subCategory, setSubCategory] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const [correctIr, setCorrectIr] = useState(true);

  const [isFormValid, setIsFormValid] = useState(false);

  const [creatingSourceDefinitionInProgress, setCreatingSourceDefinitionInProgress] = useState(false);
  const [ongoingSourceDefinitionId, setOngoingSourceDefinitionId] = useState<string | null>(null);
  const [sourceDefinitionId, setSourceDefinitionId] = useState<string | null>(null);
  const [newSourceDefinition, setNewSourceDefinition] = useState<SourceDefinition | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [newSourceDefinitionJson, setNewSourceDefinitionJson] = useState<any>(null);

  const { mutate: startSourceDefinitionImport } = useStartSourceDefinitionImport();
  const { data: sourceDefinitionStatusData } = useGetSourceDefinitionStatus(ongoingSourceDefinitionId, true);
  const { data: newSourceDefinitionData } = useGetSourceDefinitionById(sourceDefinitionId);
  const {
    state: { subscriptionInfo },
  } = useBaseContext();

  const [shouldShare, setShouldShare] = useState(false);

  // Form validation
  useEffect(() => {
    if (category && subCategory && file) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [category, subCategory, file]);

  // step 1 upload file
  const uploadSelectedFile = async () => {
    if (file) {
      setCreatingSourceDefinitionInProgress(true);
      const toastId = toast.info('Creating source definition', { className: 'editor-toast', autoClose: 3000 });
      setTimeout(() => {
        toast.dismiss(toastId);
      }, 2000);
      const fileUploadId = await uploadFileToTreble(file);
      return fileUploadId;
    }
  };

  const handleCreateSourceDefinition = async () => {
    // step 1 upload file
    const fileUploadId = await uploadSelectedFile();

    if (fileUploadId) {
      // step 2 start create Source definition job
      startSourceDefinitionImport(
        {
          category,
          subCategory,
          description,
          fileUploadId,
          correctIr,
          isSharedWithOrganization: shouldShare,
        },
        {
          onSuccess: (response) => {
            setOngoingSourceDefinitionId(response);
          },
        }
      );
    }
  };

  // step 3 polling for Source definition being created
  useEffect(() => {
    if (ongoingSourceDefinitionId && sourceDefinitionStatusData?.importStatus.status === 'Completed') {
      setSourceDefinitionId(sourceDefinitionStatusData.sourceDefinitionId);
    } else if (ongoingSourceDefinitionId && sourceDefinitionStatusData?.importStatus.status === 'Error') {
      onErrorInPolling();
    }
  }, [sourceDefinitionStatusData]);

  // step 4 Source definition is created
  useEffect(() => {
    if (newSourceDefinitionData) {
      setNewSourceDefinition(newSourceDefinitionData);
      getPlotForSourceDefinition(newSourceDefinitionData);

      const newSourceDefinitions = [...filteredSourceDefinitions, newSourceDefinitionData];
      dispatch({
        type: ActionType.SET_SOURCE_DEFINITIONS,
        payload: newSourceDefinitions,
      });

      toast.success('New source definition created!', { className: 'editor-toast' });

      setTimeout(() => {
        setCreatingSourceDefinitionInProgress(false);
      }, 700);
    }
  }, [newSourceDefinitionData]);

  // step 5 download json form created Source definition, show plot
  const getPlotForSourceDefinition = async (sourceDefinition: SourceDefinition) => {
    if (sourceDefinition) {
      const json = await downloadJson(sourceDefinition.directivity2dJsonDownloadUrl);
      if (json) {
        setNewSourceDefinitionJson(json);
      }
    }
  };

  const onChangeFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const file = Array.from(event.currentTarget.files)[0];
      setFile(file);
    }
  };

  const onDropFileUpload = (event: React.DragEvent<HTMLElement>) => {
    if (event.dataTransfer.files) {
      const file = Array.from(event.dataTransfer.files)[0];
      setFile(file);
    }
  };

  const onErrorInPolling = () => {
    setCreatingSourceDefinitionInProgress(false);
    setOngoingSourceDefinitionId(null);
  };

  const downloadJson = async (url: string) => {
    const json = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then(function (response) {
      return response.json();
    });

    return await json;
  };

  return (
    <TrblPopup
      width={newSourceDefinitionJson === null ? 690 : 1120}
      hideBackdrop={false}
      aria-labelledby={'Add new source definition'}
      sx={{ fontSize: '12px' }}
      open={true}>
      <form>
        <TrblPopupTitle onClose={() => setShowPopup(false)}>{'Add new source definition'}</TrblPopupTitle>
        <TrblPopupContent>
          {creatingSourceDefinitionInProgress && <BufferLoadingScreen top={50} height="calc(100% - 100px)" />}

          {newSourceDefinition === null || newSourceDefinitionJson === null ? (
            //  The view when inputting the new Source definition
            <>
              <SourceDefinitionInput
                description={description}
                setDescription={setDescription}
                category={category}
                setCategory={setCategory}
                subCategory={subCategory}
                setSubCategory={setSubCategory}
                correctIr={correctIr}
                setCorrectIr={setCorrectIr}></SourceDefinitionInput>

              <FileUpload
                accept={'.cf2'}
                acceptText="CLF files"
                onChange={onChangeFileUpload}
                onDropFile={onDropFileUpload}
                successIcon={<TrblClfIcon />}
                acceptIcon={<TrblReferenceCurveIcon />}
              />
            </>
          ) : (
            //  The view when import has finished, Source definition details
            <Box component={'div'} display={'inline-flex'} width={'100%'}>
              <div className={styles['box-left']}>
                <div>
                  <SourceDefinitionDetails sd={newSourceDefinition} />
                  <TableDivider />
                  <FrequenciesTable sourceDefinitionJson={newSourceDefinitionJson} />
                </div>
                <SourceDefinitionFooter sourceDefinition={newSourceDefinition} />
              </div>
              <div className={styles['box-right']}>
                <SourceDefinitionPlots sourceDefinitionJson={newSourceDefinitionJson} />
              </div>
            </Box>
          )}
        </TrblPopupContent>
        {newSourceDefinition === null || newSourceDefinitionJson === null ? (
          <TrblPopupActions>
            <div className={styles['action-container']}>
              <TrblTooltip
                title={
                  !subscriptionInfo?.hasAccessToShare ? <ShareDisabledInformation type="source definitions" /> : null
                }>
                <div>
                  <TrblToggle
                    checked={shouldShare}
                    ariaLabel="Share source definition toggle"
                    onChangeToggle={() => setShouldShare(!shouldShare)}
                    disabled={!subscriptionInfo?.hasAccessToShare || creatingSourceDefinitionInProgress}
                  />
                  <span style={{ marginLeft: '8px', opacity: !subscriptionInfo?.hasAccessToShare ? 0.7 : 1 }}>
                    {' '}
                    Share with organization{' '}
                  </span>
                </div>
              </TrblTooltip>

              <PrimaryButton
                disabled={!isFormValid || creatingSourceDefinitionInProgress}
                width={'fit-content'}
                label="Add"
                onClick={handleCreateSourceDefinition}
              />
            </div>
          </TrblPopupActions>
        ) : (
          <TrblPopupActions framed>
            <SourceDefinitionWarning />
            <PrimaryButton onClick={() => setShowPopup(false)} width={'fit-content'} label="Done" />
          </TrblPopupActions>
        )}
      </form>
    </TrblPopup>
  );
};
