import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { Box } from '@mui/material';

import { PrimaryButton } from '@/components/Shared/Buttons';
import { TrblAddIcon } from '@/components/Icons';
import { OrganizationForm, OrganizationFormState } from './OrganizationForm';
import { OrganizationsGrid } from './OrganizationsGrid';

import { useCreateOrganization, useGetOrganizations } from '../hooks';
import { useAddTokenPackage } from '../hooks/useAddTokenPackage';

export const Organizations: FC = () => {
  const navigate = useNavigate();

  const [showAddOrganization, setShowAddOrganization] = useState(false);

  const { data: organizations } = useGetOrganizations();
  const { mutateAsync: createOrganization } = useCreateOrganization();
  const { mutate: addTokenPackage } = useAddTokenPackage();

  const handleAddButtonClick = () => {
    setShowAddOrganization(true);
  };

  const handleAddOrganization = async (values: OrganizationFormState) => {
    const { tokens, packageType, ...org } = values;

    const products =
      org?.products?.map(({ expiresAt, ...p }) => {
        return {
          ...p,
          expiresAt: expiresAt != null && expiresAt != undefined ? dayjs(expiresAt).utc().format() : undefined,
        };
      }) ?? [];
    const initialUser = org?.initialUser?.email ? org.initialUser : undefined;
    const orgRequest = {
      name: org.name,
      description: org.description,
      ...(!!products.length && { products: products ?? undefined }),
      ...(!!initialUser && { initialUser }),
    };
    const newOrg = await createOrganization(orgRequest, {
      onSuccess: (organization) => {
        setShowAddOrganization(false);
        navigate(organization.id);
      },
    });

    if (!!tokens && !!newOrg) {
      addTokenPackage({ organizationId: newOrg.id, tokens: tokens, packageType: packageType });
    }
  };

  return (
    <Box component="div" display="flex" flexDirection={'column'} height="100%" gap={'6px'} padding={'13px 30px'}>
      <Box component={'div'} display="flex" alignItems={'end'} justifyContent={'space-between'}>
        <p style={{ fontWeight: '600' }}>Organizations</p>
        <PrimaryButton
          label="Add organization"
          icon={<TrblAddIcon fill="none" />}
          onClick={handleAddButtonClick}
          width={170}
          sx={{ marginBottom: '2px' }}
        />
      </Box>
      <Box component="div" flex="1 1 auto">
        {organizations && <OrganizationsGrid organizations={organizations} />}
      </Box>
      {showAddOrganization && (
        <OrganizationForm onCancel={() => setShowAddOrganization(false)} onSubmit={handleAddOrganization} />
      )}
    </Box>
  );
};
