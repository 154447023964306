import { useEffect, useState } from 'react';

import { Box, Stack } from '@mui/material';

import { PrimaryButton, SecondaryButton } from '@/components/Shared/Buttons';
import { Stepper, TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { EmptySidebar } from './components/StatusSidebar/EmptySidebar';
import { StatusNav } from './components/StatusSidebar/StatusNav';
import { StatusSidebar } from './components/StatusSidebar/StatusSidebar';
import { TrblMinimizeIcon } from '../Icons';
import { Divider } from '../Shared/Divider';
import { MultiSpaceImportStep1 } from './MultiSpaceImportStep1';
import { MultiSpaceImportStep1IFC } from './MultiSpaceImportStep1IFC';
import { MultiSpaceImportStep2 } from './MultiSpaceImportStep2';

import { useCancelSpaceExtractionTask, useGetLatestSpaceExtractionTasks, useGetSpaceExtractionResult } from '@/hooks';

import { SpaceExtractionResults, SpaceExtractionTask } from './types';

export const MultiSpaceImportPopup = ({
  setShowPopup,
  uploadModelDone,
  spaceExtractionTasksOnLoad,
  fileChosen,
  projectChosen,
}: {
  setShowPopup: (value: boolean) => void;
  uploadModelDone: (id?: string, type?: string) => void;
  spaceExtractionTasksOnLoad?: SpaceExtractionTask[];
  fileChosen?: File;
  projectChosen?: string;
}) => {
  const [stepSelected, setStepSelected] = useState(0);
  const [file, setFile] = useState<File | null>(null);
  const [filename, setFilename] = useState('');
  const [importStarted, setImportStarted] = useState(false);
  const [preprocessing, setPreprocessing] = useState(false);
  const [isTasksLoaded, setIsTasksLoaded] = useState(false);
  const [ongoingSpaceExtractionId, setOngoingSpaceExtractionId] = useState<string | null>(null);
  const [multiSpaceResults, setMultiSpaceResults] = useState<SpaceExtractionResults | null>();
  const [isSidebarActive, setIsSidebarActive] = useState(false);

  const [importError, setImportError] = useState<string | null>(null);

  const [fetchSpaceExtractionId, setFetchSpaceExtractionId] = useState<string | null>(null);
  const { data: spaceExtractionResultsData } = useGetSpaceExtractionResult(fetchSpaceExtractionId);

  const { data: spaceExtractionTasks, refetch: refetchLatestSpaceExtractionTasks } =
    useGetLatestSpaceExtractionTasks(100);
  const { mutate: cancelSpaceExtractionTask, data: cancelSpaceExtractionData } = useCancelSpaceExtractionTask();

  useEffect(() => {
    // if file chosen before popup was opened
    if (fileChosen) {
      setFile(fileChosen);
    }
  }, []);

  useEffect(() => {
    if (spaceExtractionTasksOnLoad && spaceExtractionTasksOnLoad.length > 0) {
      for (const spaceExtractionTask of spaceExtractionTasksOnLoad) {
        if (spaceExtractionTask.task.status === 'Completed' && !spaceExtractionTask.hasImportedModel) {
          setIsSidebarActive(true);
          break;
        }
      }
    }

    setIsTasksLoaded(true);
  }, [spaceExtractionTasksOnLoad]);

  useEffect(() => {
    // if new upload is started then start fetching LatestSpaceExtractionTasks to update the StatusSidebar
    refetchLatestSpaceExtractionTasks();
  }, [ongoingSpaceExtractionId]);

  // when import has completed
  useEffect(() => {
    if (multiSpaceResults) {
      setStepSelected(1);
    }
  }, [multiSpaceResults]);

  // trigger start import in Step 1
  const handleStartImport = () => {
    setImportStarted(true);
  };

  const goBack = () => {
    setStepSelected(0);
    setFile(null);
    setMultiSpaceResults(null);
  };

  useEffect(() => {
    if (spaceExtractionResultsData) {
      setMultiSpaceResults(spaceExtractionResultsData);
      setFetchSpaceExtractionId(null);
    }
  }, [spaceExtractionResultsData]);

  const goNext = (id: string, filename: string) => {
    setFetchSpaceExtractionId(id);
    setFilename(filename);
  };

  const handleCancelUpload = (id: string) => {
    cancelSpaceExtractionTask(id);
    if (id === ongoingSpaceExtractionId) {
      setImportStarted(false);
      setOngoingSpaceExtractionId(null);
      setFile(null);
    }
  };

  useEffect(() => {
    // when upload task is cancelled then refectch LatestSpaceExtractionTasks
    if (cancelSpaceExtractionData) {
      refetchLatestSpaceExtractionTasks();
    }
  }, [cancelSpaceExtractionData]);

  return (
    <TrblPopup
      minwidth={1340}
      maxwidth={2000}
      width={'90vw'}
      minheight={400}
      maxheight={1000}
      height={'90vh'}
      aria-labelledby={'Import geometry'}
      open={true}>
      <TrblPopupTitle
        onClose={() => setShowPopup(false)}
        closeIcon={file?.name.includes('.ifc') && ongoingSpaceExtractionId ? <TrblMinimizeIcon /> : undefined}>
        {'Import geometry'}
      </TrblPopupTitle>
      <TrblPopupContent style={{ overflowX: 'hidden' }}>
        <Stack margin={'0 -20px'} display={'flex'} position={'relative'}>
          <Stack width={'380px'} margin={'0 auto 12px'}>
            <Stepper steps={['Upload file', 'Import geometry']} stepSelected={stepSelected} />
          </Stack>
          {stepSelected === 0 && isTasksLoaded && (
            <StatusNav
              isSidebarActive={isSidebarActive}
              setIsSidebarActive={setIsSidebarActive}
              spaceExtractionTasks={spaceExtractionTasks}
            />
          )}
          <Divider />
        </Stack>
        <Stack
          margin={'0 -20px -16px'}
          width={'calc(100% + 40px)'}
          height={'calc(100% - 23px)'}
          flexDirection={'row'}
          position={'relative'}
          overflow={'hidden'}>
          {stepSelected === 0 && (
            <>
              {file && !file?.name.includes('.ifc') ? (
                <MultiSpaceImportStep1
                  file={file ?? fileChosen ?? null}
                  setFile={setFile}
                  importStarted={importStarted}
                  setImportStarted={setImportStarted}
                  setMultiSpaceResults={setMultiSpaceResults}
                  setImportError={setImportError}
                />
              ) : (
                <MultiSpaceImportStep1IFC
                  file={file ?? fileChosen ?? null}
                  setFile={setFile}
                  importStarted={importStarted}
                  setImportStarted={setImportStarted}
                  setPreprocessing={setPreprocessing}
                  ongoingSpaceExtractionId={ongoingSpaceExtractionId}
                  setOngoingSpaceExtractionId={setOngoingSpaceExtractionId}
                  setMultiSpaceResults={setMultiSpaceResults}
                  setImportError={setImportError}
                />
              )}
              {isTasksLoaded && (
                <StatusSidebar
                  isSidebarActive={isSidebarActive}
                  spaceExtractionTasks={spaceExtractionTasks}
                  goNext={goNext}
                  handleCancelUpload={handleCancelUpload}
                />
              )}
            </>
          )}

          {stepSelected === 1 && multiSpaceResults && (
            <MultiSpaceImportStep2
              multiSpaceResults={multiSpaceResults}
              thisFilename={filename || file?.name || ''}
              projectChosen={projectChosen}
              uploadFinished={uploadModelDone}
              goBack={goBack}
            />
          )}
        </Stack>
      </TrblPopupContent>

      {stepSelected === 0 && (
        <Stack
          width={'100%'}
          flexDirection={'row'}
          position={'relative'}
          overflow={'hidden'}
          justifyContent={'right'}
          flexShrink={0}>
          <TrblPopupActions framed>
            <Box component="div" display="flex" justifyContent="flex-end" alignItems={'center'} gap={2} width={'100%'}>
              {importError && <div style={{ color: '#ff8a8a' }}>{importError}</div>}

              {importStarted && ongoingSpaceExtractionId && (
                <SecondaryButton
                  width={'auto'}
                  label="Cancel"
                  onClick={() => handleCancelUpload(ongoingSpaceExtractionId)}
                />
              )}

              <PrimaryButton
                width={'auto'}
                label="Upload file"
                disabled={!file || importStarted || preprocessing}
                onClick={handleStartImport}
              />
            </Box>
          </TrblPopupActions>
          {isTasksLoaded && <EmptySidebar isSidebarActive={isSidebarActive} />}
        </Stack>
      )}
    </TrblPopup>
  );
};
