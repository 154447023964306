import { useEffect, useState } from 'react';

import { ActionType as ResultsActionType, useResultsContext } from '../../context/ResultsContext';
import { useResultComparisonContext } from './context/ResultComparisonContext';
import { useEditorContext } from '@/context/EditorContext';

import { TrblIconButton } from '@/components/Shared/Buttons';
import { TrblDeleteIcon, TrblDraggableIcon } from '@/components/Icons';
import { DownloadResults } from '../DownloadResults/DownloadResults';
import { SimulationForm } from './SimulationForm';
import { SimulationFormGridReceivers } from './SimulationFormGridReceivers';

import {
  useLastGridResults,
  useLastSolveResults,
  useLoadAndSelectSimulation,
  useSourceSumming,
  useUpdateResultContext,
} from './hooks';

import { getSolveResultId, shouldShowDownloadResults } from './utils';

import { ResultComparisonState } from '../../types';
import { ResultsView } from '@/context/EditorContext/types';

import classes from './styles.module.scss';

type ResultComparison = {
  defaultState: ResultComparisonState | null;
  index: number;
  color: string;
  isSelected: boolean;
  isMinimized: boolean;
};

export const ResultComparison = ({ color, defaultState, index, isSelected, isMinimized }: ResultComparison) => {
  const { dispatch: resultsDispatch, selectedPreset, selectedPresetEdited, gridReceiverSubView } = useResultsContext();
  const { state } = useResultComparisonContext();
  const { resultsView } = useEditorContext();

  // fetch the last solve result by a simulation id once the user selects a simulation
  const [simulationId, setSimulationId] = useState('');

  useEffect(() => {
    if (defaultState?.selectedSimulation) {
      setSimulationId(defaultState.selectedSimulation.id);
    }
  }, [defaultState]);

  useLoadAndSelectSimulation({ simulationId, defaultState, color });

  const {
    handleStartSummingProcess,
    handleCancelSummingProcess,
    handleUpdateSourceSummingLabel,
    hasFetchedSourceSummingResults,
  } = useSourceSumming({ isInGridReceiverView: resultsView === ResultsView.ResultsGridReceiversView });

  useLastSolveResults({
    hasFetchedSourceSummingResults,
  });

  useLastGridResults({
    simulationId,
    isInGridReceiverView: resultsView === ResultsView.ResultsGridReceiversView,
  });

  const onFormChange = () => {
    if (selectedPreset && !selectedPresetEdited) {
      resultsDispatch({ type: ResultsActionType.SET_PRESET_EDITED, presetEdited: true });
    }
  };

  const { handleRemoveComparison, handleSelectComparison } = useUpdateResultContext({
    index,
    color,
    state,
    onFormChange,
  });

  return (
    <div
      onClick={handleSelectComparison}
      className={`${classes.simulation_selector__container} ${isSelected ? classes.selected : ''} ${
        isMinimized ? classes.minimized : ''
      } ${
        resultsView !== ResultsView.ResultsGridReceiversView ||
        state.selectedSimulation?.lastSimulationRun?.gridReceivers
          ? ''
          : classes.no_receivers
      }`}>
      {resultsView !== ResultsView.ResultsGridReceiversView ? (
        <SimulationForm
          spaceName={state.spaceName}
          modelName={state.modelName}
          color={color}
          isReflectionResults={resultsView === ResultsView.ResultsReflectogramView}
          onFormChange={onFormChange}
          onStartSourceSumming={handleStartSummingProcess}
          onCancelSourceSumming={handleCancelSummingProcess}
          onUpdateSourceSummingLabel={handleUpdateSourceSummingLabel}
        />
      ) : (
        <SimulationFormGridReceivers
          spaceName={state.spaceName}
          modelName={state.modelName}
          color={color}
          isModalAnalysisView={gridReceiverSubView === 'modal'}
          onFormChange={onFormChange}
          onStartSourceSumming={handleStartSummingProcess}
          onCancelSourceSumming={handleCancelSummingProcess}
          onUpdateSourceSummingLabel={handleUpdateSourceSummingLabel}
        />
      )}
      <div className={classes.simulation_selector__grabber}>
        <TrblDraggableIcon />
      </div>
      <div className={classes.simulation_selector__footer}>
        {shouldShowDownloadResults(resultsView, state.simulationData) && (
          <DownloadResults
            selectedSimulation={state.selectedSimulation}
            solveResultId={getSolveResultId(state.simulationData)}
            simulationId={state.simulationId}
          />
        )}
        <TrblIconButton
          onClick={handleRemoveComparison}
          className={classes.remove_button}
          label="Remove comparison"
          icon={<TrblDeleteIcon />}
        />
      </div>
    </div>
  );
};
