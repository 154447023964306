import { FC, useState } from 'react';
import { EditSharp } from '@mui/icons-material';

import { Box, IconButton, Stack, TextField } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { OrganizationForm, OrganizationFormState } from '../Organizations/OrganizationForm';

import { OrganizationResponse, useUpdateOrganization } from '../hooks';

import styles from './styles.module.scss';

type OrganizationDetailsProps = {
  organization?: OrganizationResponse;
  canEdit: boolean;
};

export const OrganizationDetails: FC<OrganizationDetailsProps> = ({ organization, canEdit }) => {
  const [isEditingOrganization, setIsEditingOrganization] = useState(false);

  // Update organization
  const { mutate: updateOrganization } = useUpdateOrganization();

  const handleSubmitOrganization = async (organizationFormState: OrganizationFormState) => {
    updateOrganization({ organizationFormState });
    setIsEditingOrganization(false);
  };

  return (
    <Box component="div" display={'flex'} flexDirection={'column'} gap={'6px'} height={'100%'}>
      <Box component={'div'} display="flex" justifyContent={'space-between'} alignItems={'center'}>
        <p style={{ fontWeight: '600' }}>Organization details</p>
        {canEdit && (
          <TrblTooltip title="Edit organization">
            <IconButton
              onClick={() => setIsEditingOrganization(true)}
              aria-label="Edit organization"
              size={'small'}
              sx={{ marginBottom: '-2px' }}>
              <EditSharp sx={{ width: '14px', height: '14px' }} />
            </IconButton>
          </TrblTooltip>
        )}
      </Box>
      <Box component="div" display={'flex'} flex={'1 1 0'} bgcolor={'#272727'} borderRadius={'4px'}>
        <Stack spacing={1.8} padding="24px 10px 12px" flexGrow={1}>
          <TextField
            className={styles['text-field-input']}
            InputProps={{
              disabled: true,
            }}
            label="Name"
            value={organization?.organization.name || ''}
          />

          <TextField
            className={styles['text-field-input']}
            InputProps={{
              disabled: true,
            }}
            label="Description"
            multiline
            minRows={2}
            value={organization?.organization.description || ''}
          />
        </Stack>
      </Box>
      {isEditingOrganization && organization && (
        <OrganizationForm
          organization={organization.organization}
          onCancel={() => setIsEditingOrganization(false)}
          onSubmit={handleSubmitOrganization}
        />
      )}
    </Box>
  );
};
