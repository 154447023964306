import { useEffect, useState } from 'react';

import { ActionType, useResultsContext } from '@/components/Results/context/ResultsContext';

import { TrblExpandFromCornerIcon, TrblMinimizeIcon } from '@/components/Icons';
import { TrblCollapseFromCornerIcon } from '@/components/Icons/TrblCollapseFromCornerIcon';
import { FreqResponsePlot } from '../Viewport/GridReceiverResults/FreqResponsePlot';

import { LARGE_PLOT_DIMENSIONS, SMALL_PLOT_DIMENSIONS } from './constants';

import styles from './styles.module.scss';

export const FrequencyResponsePopup = () => {
  const [yData, setYData] = useState<number[]>([]);
  const [xData, setXData] = useState<number[]>([]);
  const [isLarge, setIsLarge] = useState(false);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const [smallPlotDimensions, setSmallPlotDimensions] = useState(SMALL_PLOT_DIMENSIONS);
  const [largePlotDimensions, setLargePlotDimensions] = useState(LARGE_PLOT_DIMENSIONS);

  useEffect(() => {
    const element = document.getElementById('viewport');
    const rect = element?.getBoundingClientRect();

    const maxHeight = rect?.height ?? SMALL_PLOT_DIMENSIONS.height;
    const maxWidth = rect?.width ?? SMALL_PLOT_DIMENSIONS.width;
    const smallPlot = { width: maxWidth * 0.4, height: maxHeight * 0.6 };
    const largePlot = { width: maxWidth * 0.6, height: maxHeight * 0.7 };

    const LARGE_VIEWPORT_WIDTH = 550;

    if (maxHeight > LARGE_VIEWPORT_WIDTH) {
      setSmallPlotDimensions({ ...smallPlot, width: maxWidth * 0.5 });
      setLargePlotDimensions({ ...largePlot, height: maxHeight * 0.8 });
      setDimensions({ ...smallPlot, width: maxWidth * 0.5 });
    } else {
      setSmallPlotDimensions(smallPlot);
      setLargePlotDimensions(largePlot);
      setDimensions(smallPlot);
    }
  }, []);

  const { dispatch, modalAnalysisReceiverData, receiverSquare, modalAnalysisFrequencyValues, modalFrequencySelected } =
    useResultsContext();

  const toggleSize = () => {
    setDimensions(isLarge ? smallPlotDimensions : largePlotDimensions);
    setIsLarge(!isLarge);
  };

  useEffect(() => {
    if (modalAnalysisReceiverData && receiverSquare && modalAnalysisFrequencyValues) {
      const receiverData = modalAnalysisReceiverData[receiverSquare.pointId];
      const selectedData = receiverData[`Receiver_${receiverSquare.gridIndex}`];
      if (selectedData) {
        setYData(selectedData);
        setXData(modalAnalysisFrequencyValues[receiverSquare.pointId]);
      }
    } else if (receiverSquare === null) {
      setYData([]);
      setXData([]);
    }
  }, [modalAnalysisReceiverData, receiverSquare, modalAnalysisFrequencyValues]);

  const closePopup = () => {
    dispatch({
      type: ActionType.SHOW_FREQ_RESPONSE_POPUP,
      showFreqResponsePopup: false,
    });
  };

  return (
    <div className={styles['freq-popup']} style={{ width: `${dimensions.width}px`, height: `${dimensions.height}px` }}>
      <FreqResponsePlot yData={yData} xData={xData} selectedFrequency={modalFrequencySelected} />
      <button className={styles['minimize-btn']} onClick={closePopup}>
        <TrblMinimizeIcon />
      </button>
      <button className={styles['toggle-btn']} onClick={toggleSize}>
        {isLarge ? <TrblCollapseFromCornerIcon /> : <TrblExpandFromCornerIcon />}
      </button>
    </div>
  );
};
