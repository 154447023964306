import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// @ts-expect-error jsfive does not have a type definition file
import * as hdf5 from 'jsfive';

import { ModalResultDtoModalResultDto } from '@/components/Results/components/ResultComparison/hooks';

import { SourceResultForGridReceiverDto, SourceResults } from '@/types';

export const useForcedModalAnalysis = (
  modalAnalysisResults: ModalResultDtoModalResultDto[] | undefined,
  gridReceiverIds: string[],
  selectedSource: SourceResults | SourceResultForGridReceiverDto | undefined
) => {
  const [frequencyData, setFrequencyData] = useState<Record<string, Record<number, number[]>> | null>(null);
  const [receiverData, setReceiverData] = useState<Record<string, Record<string, number[]>> | null>(null);
  const [frequencyValues, setFrequencyValues] = useState<Record<string, number[]> | null>(null);
  const [minMaxData, setMinMaxData] = useState<Record<string, { Min: number; Max: number }> | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      setIsLoading(true);
      if (!modalAnalysisResults || gridReceiverIds.length === 0) {
        setIsLoading(false);
        return;
      }

      const selectedResult = modalAnalysisResults.find(
        (result) => result.sourcePointId === selectedSource?.sourcePointId
      );

      if (!selectedResult?.modalResultDownloadUrl) {
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(selectedResult.modalResultDownloadUrl);
        const arrayBuffer = await response.arrayBuffer();
        const h5File = new hdf5.File(arrayBuffer);

        const frequencyData: Record<string, Record<number, number[]>> = {};
        const minMaxData: Record<string, { Min: number; Max: number }> = {};
        const receiversData: Record<string, Record<string, number[]>> = {};
        const receiverFrequencies: Record<string, number[]> = {};

        for (const gridReceiverId of gridReceiverIds) {
          const receiverKeys = h5File.get('GridReceivers').get(gridReceiverId).get('SPL').get('Receivers').keys;
          const frequencies: number[] = h5File.get(`GridReceivers/${gridReceiverId}/Frequency`).value;
          const attributes = h5File.get(`/GridReceivers/${gridReceiverId}/SPL`).attrs;
          const frequencyValues: Record<number, number[]> = {};
          const receiverDataForReceiver: Record<string, number[]> = {};

          receiverFrequencies[gridReceiverId] = frequencies;

          for (const frequency of frequencies) {
            const formattedFrequency = frequency.toString().padStart(4, '0');
            const frequencyPath = `GridReceivers/${gridReceiverId}/SPL/F_${formattedFrequency}`;
            const frequencyDataset = h5File.get(frequencyPath);
            if (frequencyDataset) {
              frequencyValues[frequency] = frequencyDataset.value;
            } else {
              console.warn(`Frequency ${frequency} not found for ID: ${gridReceiverId}`);
            }
          }

          for (const receiverKey of receiverKeys) {
            const receiverPath = `GridReceivers/${gridReceiverId}/SPL/Receivers/${receiverKey}`;
            const receiverDataset = h5File.get(receiverPath);
            if (receiverDataset) {
              receiverDataForReceiver[receiverKey] = receiverDataset.value;
            } else {
              console.warn(`Receiver ${receiverKey} not found.`);
            }
          }

          frequencyData[gridReceiverId] = frequencyValues;
          receiversData[gridReceiverId] = receiverDataForReceiver;
          minMaxData[gridReceiverId] = { ...attributes };
        }

        setReceiverData(receiversData);
        setFrequencyValues(receiverFrequencies);
        setFrequencyData(frequencyData);
        setMinMaxData(minMaxData);
      } catch (error) {
        toast.error('Failed to fetch and process data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [modalAnalysisResults, gridReceiverIds, selectedSource]);

  return { frequencyData, receiverData, frequencyValues, minMaxData, isLoading };
};
