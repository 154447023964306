import { AxisType, Layout } from 'plotly.js';

import {
  FREQUENCY_VALUES_FOR_RESPONSE_PLOT,
  TICK_TEXT_FOR_RESPONSE_PLOT,
} from '@/components/Results/components/ResponsePlot/constants';

export const LAYOUT_CONFIG: Partial<Layout> = {
  autosize: true,
  uirevision: 'true', // Needed so that the zoom persists after a re-render (changing bar selection)
  xaxis: {
    gridcolor: '#555555',
    hoverformat: '',
    range: [Math.log10(20), Math.log10(12000)],
    tickmode: 'array' as 'array' | 'linear' | 'auto' | undefined,
    type: 'log' as AxisType,
    ticktext: TICK_TEXT_FOR_RESPONSE_PLOT,
    tickvals: FREQUENCY_VALUES_FOR_RESPONSE_PLOT,
    title: {
      text: 'FREQUENCY [Hz]',
      standoff: 20,
      font: {
        size: 10,
        color: '#ADADAD',
      },
    },
  },

  yaxis: {
    gridcolor: '#555555',
    hoverformat: '.6f',
    autorange: true,
    title: {
      text: 'SPL [dB] re. 20 μPa',
      font: {
        size: 10,
        color: '#ADADAD',
      },
      standoff: 10,
    },
  },

  paper_bgcolor: '#272727',
  plot_bgcolor: 'transparent',
  font: {
    family: 'Inter, Helvetica, Arial, sans-serif',
    color: '#f5f5f5',
    size: 10,
  },
  margin: {
    l: 40,
    r: 5,
    b: 50,
    t: 15,
    pad: 5,
  },
};
