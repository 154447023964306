import { useQuery } from '@tanstack/react-query';

import { ProjectAndSpacesDto } from '@/types';

import axios from '@/axios';

const getProjectById = async (projectId: string) => {
  const { data } = await axios.get(`/api/v2/Project/GetProjectById?id=${projectId}`);

  return await data;
};

export const useGetProjectById = (projectId: string) =>
  useQuery<ProjectAndSpacesDto, boolean>(['projectById', projectId], () => getProjectById(projectId), {
    enabled: !!projectId,
    refetchOnWindowFocus: false,
  });
