import { useMemo } from 'react';

import { ResultComparison } from '@/components/Results/types';

export const useParameterValuesForSelectedSource = (selectedComparison: ResultComparison | null) => {
  return useMemo(() => {
    const formState = selectedComparison?.formState;
    const selectedSourceIds = formState?.sourcePointIds;
    const selectedSourceKey = selectedSourceIds?.sort((a, b) => a.localeCompare(b)).join(';');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let allParameterValues: Record<string, Record<string, any>> | undefined;
    if (selectedSourceIds?.length) {
      allParameterValues =
        selectedSourceIds.length > 1
          ? formState?.simulationData?.gridReceiverParameterValuesForSummedSources
          : formState?.simulationData?.gridReceiverParameterValues;
    }

    if (
      allParameterValues &&
      Object.keys(allParameterValues).length &&
      selectedSourceKey &&
      allParameterValues[selectedSourceKey]
    ) {
      return allParameterValues[selectedSourceKey];
    }

    return null;
  }, [
    selectedComparison?.formState?.simulationData?.gridReceiverParameterValues,
    selectedComparison?.formState?.simulationData?.gridReceiverParameterValuesForSummedSources,
    selectedComparison?.formState?.sourcePointIds,
  ]);
};
