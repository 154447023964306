export * from './createInterpolatedHeatmap';
export * from './createSimpleHeatmap';

import { Size } from '@react-three/fiber';
import { Sprite, SpriteMaterial, TextureLoader } from 'three';
import SpriteText from 'three-spritetext';

import targetImageUrl from '../../images/target.png';

const targetTexture = new TextureLoader().load(targetImageUrl);

export const targetMaterial = new SpriteMaterial({ map: targetTexture });
targetMaterial.transparent = true;
targetMaterial.alphaTest = 0.1;
targetMaterial.sizeAttenuation = false;
targetMaterial.depthTest = false;
targetMaterial.depthWrite = false;
targetMaterial.color.set(0xcccccc);

export const makePressureText = (text: string, size: Size, pos: number[]) => {
  const myText = new SpriteText(text, 9.5);
  myText.position.set(pos[0], pos[1], pos[2]);
  myText.fontFace = '"Inter", Helvetica, Arial';
  myText.fontWeight = '500';
  myText.fontSize = 24;
  myText.color = '#ffffff';
  myText.center.set(-0.325, 0.515);
  myText.backgroundColor = '#282828';
  myText.borderColor = '#444444';
  myText.borderWidth = 0.5;
  myText.borderRadius = 4;
  myText.padding = 3;

  myText.scale.set(myText.scale.x / size.height, myText.scale.y / size.height, 0);
  myText.material.alphaTest = 0.15;
  myText.material.sizeAttenuation = false;
  myText.material.color.set(0xaaaaaa);
  myText.material.depthTest = false;
  myText.material.depthWrite = false;
  myText.renderOrder = 20;

  return myText;
};

export const makeCursorTarget = (size: Size, pos: number[]) => {
  const sprite = new Sprite(targetMaterial);
  sprite.position.set(pos[0], pos[1], pos[2]);
  sprite.renderOrder = 20;
  sprite.scale.set(16, 16, 1);
  sprite.scale.set(sprite.scale.x / size.height, sprite.scale.y / size.height, 0);
  return sprite;
};
