import { useMemo } from 'react';

import { ActionType, useResultsContext } from '@/components/Results/context/ResultsContext';

// @ts-expect-error Could not find a declaration file for module '@/utils/ColorBar'.
import { ColorBar } from '@/utils/ColorBar';
import { roundFloat } from '@/utils/trebleFunctions';

import { ParameterValuesForSelectedSource } from '../types';
import { GridReceiverResultsDto } from '@/types';

export const useTargetValueColorBar = (
  colorBar: ColorBar,
  parameterValuesForSelectedSource: ParameterValuesForSelectedSource | null,
  gridReceiverResultsToDisplay: GridReceiverResultsDto[],
  parameterDecimals: number
) => {
  const {
    surfaceReceiversSelectedParameter: selectedParameter,
    surfaceReceiversSelectedFrequency: selectedFrequency,
    surfaceReceiversSelectedNcCurve: selectedNcCurve,
    targetValue,
    targetValueMax,
    targetType,
    dispatch,
  } = useResultsContext();

  return useMemo(() => {
    if (targetValue !== undefined && parameterValuesForSelectedSource && selectedParameter && selectedFrequency) {
      const colorBar = new ColorBar('targetValue');
      const visibleGridReceiverIds = gridReceiverResultsToDisplay.map((x) => x.pointId);
      const validValuesChosen: number[] = [];

      Object.keys(parameterValuesForSelectedSource)
        .filter((x) => visibleGridReceiverIds.includes(x))
        .forEach((gridReceiverId: string) => {
          const valuesChosen: number[] =
            // @ts-expect-error Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'ParameterValues'.
            parameterValuesForSelectedSource[gridReceiverId][selectedParameter][
              selectedParameter !== 'sti' ? selectedFrequency! : selectedNcCurve!
            ];
          // Filter out non finite numbers (NaN, Infinity) and values outside interval -150 to 150
          // if multiple surface receivers then push all the values together into one array
          validValuesChosen.push(
            ...valuesChosen
              .filter((n: number) => Math.abs(n) < 150 && isFinite(n))
              .map((value) => roundFloat(value, parameterDecimals))
          );
        });

      const min = Math.min(...validValuesChosen);
      const max = Math.max(...validValuesChosen);
      const offset = Math.abs(max - min) / 1000;

      if (targetValueMax !== undefined && targetType === 'Range') {
        colorBar.setMin(targetValue);
        colorBar.setMax(targetValueMax);
      } else if (targetType === 'Above' || targetType === 'Range') {
        colorBar.setMin(targetValue);
        colorBar.setMax(max);
      } else {
        colorBar.setMin(min);
        colorBar.setMax(targetValue + offset);
      }

      // calculate percentage
      const valuesWithinTarget = validValuesChosen.filter(
        (value) => value <= colorBar.getMax() && value >= colorBar.getMin()
      );
      const targetPercentage = (valuesWithinTarget.length / validValuesChosen.length) * 100;

      dispatch({
        type: ActionType.SET_TARGET_PERCENTAGE,
        payload: roundFloat(targetPercentage, 1),
      });

      return colorBar;
    }

    return null;
  }, [targetValue, targetValueMax, targetType, colorBar]);
};
