import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import { UserDto } from './types';

import axios from '@/axios';

export type CreateUserRequest = {
  email: string;
  firstName?: string;
  lastName?: string;
  trialExpiresAt?: string;
  organizationId: string;
};

export type CreateUserResponse = UserDto;

const createUser = async (user: CreateUserRequest) => {
  const { data } = await axios.post<CreateUserResponse>(`/api/Admin/CreateUser`, user);

  return data;
};

export const useCreateUser = () => {
  return useMutation(async (data: CreateUserRequest) => await createUser(data), {
    onError: () => toast.error('An error occurred while creating the user!'),
  });
};
