import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblPauseIcon: FC<IconProps> = ({ height = '10', width = '10', fill = '#DADADA' }) => (
  <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_3171_170276)">
      <path
        d="M2 1.42857C2 1.19188 2.19188 1 2.42857 1H3.57143C3.80812 1 4 1.19188 4 1.42857V8.57143C4 8.80812 3.80812 9 3.57143 9H2.42857C2.19188 9 2 8.80812 2 8.57143V1.42857Z"
        fill={fill}
      />
      <path
        d="M6 1.42857C6 1.19188 6.19188 1 6.42857 1H7.57143C7.80812 1 8 1.19188 8 1.42857V8.57143C8 8.80812 7.80812 9 7.57143 9H6.42857C6.19188 9 6 8.80812 6 8.57143V1.42857Z"
        fill={fill}
      />
      <path
        d="M2 1.42857C2 1.19188 2.19188 1 2.42857 1H3.57143C3.80812 1 4 1.19188 4 1.42857V8.57143C4 8.80812 3.80812 9 3.57143 9H2.42857C2.19188 9 2 8.80812 2 8.57143V1.42857Z"
        stroke="white"
        strokeWidth="0.428571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 1.42857C6 1.19188 6.19188 1 6.42857 1H7.57143C7.80812 1 8 1.19188 8 1.42857V8.57143C8 8.80812 7.80812 9 7.57143 9H6.42857C6.19188 9 6 8.80812 6 8.57143V1.42857Z"
        stroke="white"
        strokeWidth="0.428571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_3171_170276"
        x="1.78613"
        y="0.785706"
        width="6.85631"
        height="8.85716"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.428571" dy="0.428571" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.375 0 0 0 0 0.375 0 0 0 0 0.375 0 0 0 0.18 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3171_170276" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3171_170276" result="shape" />
      </filter>
    </defs>
  </svg>
);
