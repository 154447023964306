import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { GeometryImportProvider } from '@/context/GeometryImportContext';

import { FileUpload } from '@/components/Shared/FileUpload';
import { TrblGeometryFolderIcon, TrblIfcIcon, TrblModelsIcon } from '@/components/Icons';
import { MultiSpaceImportPopup } from '@/components/MultiSpaceImport';

import { useGetLatestSpaceExtractionTasks } from '@/hooks';

export const ProjectGeometryImport = ({ projectId, projectName }: { projectId: string; projectName: string }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [showModelImportPopup, setShowModelImportPopup] = useState(false);
  const [fileChosen, setFileChosen] = useState<File | null>(null);
  const [resetFile, setResetFile] = useState(false);

  const { data: spaceExtractionTasks } = useGetLatestSpaceExtractionTasks(100, showModelImportPopup);

  const uploadModelDone = (id?: string, type?: string) => {
    setShowModelImportPopup(false);

    if (projectId == id) {
      queryClient.invalidateQueries(['projectById', projectId]);
    } else {
      if (type == 'project') {
        navigate(`/new/project/${id}`);
      } else if (type == 'space') {
        navigate(`/space/${id}`);
      }
    }
  };

  const onChangeFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const file = Array.from(event.currentTarget.files)[0];
      openMultiSpaceImportPopup(file);
    }
  };

  const onDropFileUpload = (event: React.DragEvent<HTMLElement>) => {
    if (event.dataTransfer.files) {
      const file = Array.from(event.dataTransfer.files)[0];
      openMultiSpaceImportPopup(file);
    }
  };

  const openMultiSpaceImportPopup = (file: File) => {
    setFileChosen(file);
    setShowModelImportPopup(true);
    setResetFile(false);
  };

  useEffect(() => {
    if (!showModelImportPopup) {
      setResetFile(true);
    }
  }, [showModelImportPopup]);

  return (
    <>
      <FileUpload
        accept={'.ifc, .dxf, .3dm, .obj'}
        acceptText="IFC, DXF, OBJ or 3DM files accepted"
        label="Upload file to a space"
        onChange={onChangeFileUpload}
        onDropFile={onDropFileUpload}
        idleIcon={<TrblGeometryFolderIcon width="40" height="42" fill={'#3a3a3a'} />}
        successIcon={<TrblIfcIcon width="36" height="42" />}
        acceptIcon={<TrblModelsIcon width="18" height="18" />}
        shouldReset={resetFile}
        small
      />

      {showModelImportPopup && spaceExtractionTasks && fileChosen && (
        <GeometryImportProvider>
          <MultiSpaceImportPopup
            setShowPopup={setShowModelImportPopup}
            uploadModelDone={uploadModelDone}
            spaceExtractionTasksOnLoad={spaceExtractionTasks}
            fileChosen={fileChosen}
            projectChosen={projectName}
          />
        </GeometryImportProvider>
      )}
    </>
  );
};
