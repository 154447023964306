import { useMemo } from 'react';

import { useResultsContext } from '@/components/Results/context/ResultsContext';

// @ts-expect-error Could not find a declaration file for module '@/utils/ColorBar'.
import { ColorBar } from '@/utils/ColorBar';
import { getParameterDecimals, roundFloat } from '@/utils/trebleFunctions';

import { ParameterValuesForSelectedSource } from '../types';
import { GridReceiverResultsDto } from '@/types';

export const useColorBar = (
  customMin: number | null,
  customMax: number | null,
  parameterValuesForSelectedSource: ParameterValuesForSelectedSource | null,
  modalAnalysisMinMax: Record<string, { Min: number; Max: number }> | null,
  gridReceiverResultsToDisplay: GridReceiverResultsDto[],
  colorMap: string
) => {
  const {
    surfaceReceiversSelectedParameter: selectedParameter,
    surfaceReceiversSelectedFrequency: selectedFrequency,
    modalFrequencySelected,
    gridReceiverSubView,
  } = useResultsContext();

  return useMemo(() => {
    if (gridReceiverSubView === 'modal' && modalAnalysisMinMax && gridReceiverResultsToDisplay) {
      const colorBar = new ColorBar(colorMap);

      let overallMin: number | null = null;
      let overallMax: number | null = null;

      const visibleGridReceiverIds = gridReceiverResultsToDisplay.map((x) => x.pointId);
      Object.keys(modalAnalysisMinMax)
        .filter((modalAnalysisId) => visibleGridReceiverIds.includes(modalAnalysisId))
        .forEach((gridReceiverId: string) => {
          const max = modalAnalysisMinMax[gridReceiverId].Max;
          const min = modalAnalysisMinMax[gridReceiverId].Min;
          if (overallMax === null || max > overallMax) {
            overallMax = max;
          }
          if (overallMin === null || min < overallMin) {
            overallMin = min;
          }
        });

      colorBar.setMin(customMin ?? overallMin);
      colorBar.setMax(customMax ?? overallMax);
      return colorBar;
    } else if (gridReceiverSubView === 'grid' && parameterValuesForSelectedSource && selectedParameter) {
      if (selectedParameter !== 'sti' && selectedParameter !== 'd50' && selectedFrequency) {
        const colorBar = new ColorBar(colorMap);

        let min: number | null = null;
        let max: number | null = null;

        const decimals = getParameterDecimals(selectedParameter);
        const visibleGridReceiverIds = gridReceiverResultsToDisplay.map((x) => x.pointId);
        Object.keys(parameterValuesForSelectedSource)
          .filter((x) => visibleGridReceiverIds.includes(x))
          .forEach((gridReceiverId: string) => {
            const valuesChosen: number[] =
              // @ts-expect-error Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'ParameterValues'.
              parameterValuesForSelectedSource[gridReceiverId][selectedParameter][selectedFrequency];
            if (valuesChosen) {
              // Filter out non finite numbers (NaN, Infinity) and values outside interval -150 to 150
              const validValuesChosen = valuesChosen
                .filter((n: number) => Math.abs(n) < 150 && isFinite(n))
                .map((value) => roundFloat(value, decimals));
              const minValue = Math.min(...validValuesChosen);
              const maxValue = Math.max(...validValuesChosen);

              if (min === null || minValue < min) {
                min = minValue;
              }
              if (max === null || maxValue > max) {
                max = maxValue;
              }
            }
          });

        colorBar.setMin(customMin ?? min);
        colorBar.setMax(customMax ?? max);
        return colorBar;
      } else if (selectedParameter === 'd50') {
        const colorBar = new ColorBar();
        colorBar.setMin(0);
        colorBar.setMax(1);
        return colorBar;
      } else if (selectedParameter === 'sti') {
        const colorBar = new ColorBar('sti', false);
        colorBar.setMin(0);
        colorBar.setMax(1);
        return colorBar;
      }
    }
    return null;
  }, [
    customMin,
    customMax,
    parameterValuesForSelectedSource,
    modalAnalysisMinMax,
    gridReceiverResultsToDisplay,
    selectedFrequency,
    selectedParameter,
    colorMap,
    gridReceiverSubView,
    modalFrequencySelected,
  ]);
};
