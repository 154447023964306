import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblFolderSharedIcon: FC<IconProps> = ({ fill = '#DADADA', width = '14', height = '14' }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.846 2.62501H13.125C13.6082 2.62501 14 3.01676 14 3.50001V12.25C14 12.7333 13.6082 13.125 13.125 13.125H0.875C0.391751 13.125 0 12.7333 0 12.25V1.75001C0 1.26676 0.391751 0.875009 0.875 0.875009H5.25C5.58106 0.873535 5.88437 1.05977 6.03283 1.35568L6.58525 2.46401C6.6347 2.56269 6.73562 2.62501 6.846 2.62501ZM6.68124 4.55646C6.49939 4.34864 6.18351 4.32759 5.9757 4.50944C5.76789 4.69129 5.74684 5.00717 5.92868 5.21498L6.4578 5.81966L2.50335 5.81966C2.22721 5.81966 2.00335 6.04352 2.00335 6.31966C2.00335 6.5958 2.22721 6.81966 2.50335 6.81966H6.4578L5.92868 7.42434C5.74684 7.63215 5.76789 7.94803 5.9757 8.12988C6.18351 8.31173 6.49939 8.29068 6.68124 8.08286L7.936 6.64892C8.10096 6.4604 8.10096 6.17891 7.936 5.9904L6.68124 4.55646ZM3.77638 8.53465C3.95823 8.32684 3.93717 8.01096 3.72936 7.82911C3.52155 7.64726 3.20567 7.66831 3.02382 7.87613L1.82062 9.25114C1.65566 9.43965 1.65566 9.72115 1.82062 9.90966L3.02382 11.2847C3.20567 11.4925 3.52155 11.5135 3.72936 11.3317C3.93717 11.1498 3.95823 10.834 3.77638 10.6261L3.29882 10.0804H7.25328C7.52942 10.0804 7.75328 9.85654 7.75328 9.5804C7.75328 9.30426 7.52942 9.0804 7.25328 9.0804H3.29882L3.77638 8.53465Z"
      fill={fill}
    />
  </svg>
);
