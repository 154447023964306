import { FC, useEffect, useState } from 'react';

import { NameMarker } from './NameMarker';

import { getColorById, getMarkerContentForMember, getMarkerTooltipForMember, getRestOfMemberNames } from './utils';
import { sortMembers } from '@/utils/trebleFunctions';

import { UserBasicDto } from '@/types';

import classes from './styles.module.scss';

type NameMarkerRowProps = {
  projectUsers: UserBasicDto[];
  loggedInUserId?: string;
  creatorId: string;
  background?: string;
  size?: 'small' | 'medium' | 'large';
};

export const NameMarkerRowNew: FC<NameMarkerRowProps> = ({
  projectUsers,
  loggedInUserId,
  creatorId,
  background = '#1f1f1f',
  size = 'large',
}) => {
  const [projectMembers, setProjectMembers] = useState<UserBasicDto[]>([]);

  useEffect(() => {
    if (loggedInUserId) {
      // filter out the logged in user from the list
      const sortedProjectUsers = sortMembers(projectUsers.filter((user) => user.id != loggedInUserId));
      setProjectMembers(sortedProjectUsers);
    } else {
      setProjectMembers(projectUsers);
    }
  }, [projectUsers]);

  return (
    <div className={`${classes.project_members_row} ${classes.gap}`} onClick={(e) => e.stopPropagation()}>
      {projectMembers.length > 5 && (
        <NameMarker
          key="RestOfMembers"
          content={`+${projectMembers.length - 4}`}
          tooltip={getRestOfMemberNames(projectMembers, creatorId)}
          background="#D04AFF"
          size={size}
          lineStyle="solid"
          color={background}
        />
      )}
      {projectMembers
        .slice(0)
        .reverse()
        .map((member, index) => {
          if (projectMembers.length - 5 < index || projectMembers.length == 5) {
            return (
              <NameMarker
                key={member.id}
                content={getMarkerContentForMember(member)}
                tooltip={getMarkerTooltipForMember(member, creatorId)}
                background={getColorById(member.id)}
                size={size}
                lineStyle="solid"
                color={background}
              />
            );
          }
        })}
    </div>
  );
};
