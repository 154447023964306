import { FC, ReactNode } from 'react';

import styles from './styles.module.scss';

type CardsHeaderProps = {
  children: ReactNode;
};

export const CardsHeader: FC<CardsHeaderProps> = ({ children }) => {
  return <div className={styles['cards-header']}>{children}</div>;
};
