import { ButtonHTMLAttributes, FC } from 'react';

import styles from './styles.module.scss';

interface TrblActionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  round?: boolean;
  stayActive?: boolean;
}

export const TrblActionButton: FC<TrblActionButtonProps> = ({ children, round, stayActive, ...rest }) => {
  return (
    <button
      className={`${styles['action-button']} ${round ? styles['round'] : ''}  ${
        stayActive ? styles['stay-active'] : ''
      }`}
      {...rest}>
      {children}
    </button>
  );
};
