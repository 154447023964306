import { FC, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import { AccountProfile } from '@/components/AccountProfile';

import styles from './styles.module.scss';

import trebleLogoUrl from '@/images/treble-logo.png';

type HeaderProps = {
  hasSidepanel: boolean;
  mainContent?: ReactNode;
  sideContent?: ReactNode;
  mainContentMinWidth?: string;
};

export const Header: FC<HeaderProps> = ({ hasSidepanel, mainContent, sideContent, mainContentMinWidth }) => {
  const location = useLocation();

  return (
    <header className={styles['header-container']}>
      {location.pathname === '/' ? (
        <a href="/">
          <img src={trebleLogoUrl} alt="Logo" />
        </a>
      ) : (
        <Link to="/">
          <img src={trebleLogoUrl} alt="Logo" />
        </Link>
      )}
      <div
        style={{
          minWidth: mainContentMinWidth,
          width: hasSidepanel ? `calc(100% - 352px)` : undefined,
        }}
        className={styles['header-content-main']}>
        {mainContent}
      </div>
      <div className={styles['header-content-side']} style={hasSidepanel ? { width: '352px' } : undefined}>
        <Box component="div" alignItems={'center'} flex="1 1 0">
          {sideContent}
        </Box>

        <AccountProfile />
      </div>
    </header>
  );
};
