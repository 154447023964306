import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblLearnIcon: FC<IconProps> = ({ fill = '#DADADA', width = '14', height = '14' }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7082 4.63774L7.17341 2.04344C7.06278 1.99952 6.93956 1.99952 6.82893 2.04344L0.294167 4.63774C0.113249 4.70974 -0.00396353 4.88649 0.000102513 5.08117V8.72197C0.000102513 9.10865 0.313572 9.42212 0.700256 9.42212C1.08694 9.42212 1.40041 9.10865 1.40041 8.72197V6.55616C1.4006 6.40485 1.47413 6.26303 1.59766 6.17566C1.7212 6.08829 1.87942 6.06623 2.02215 6.11646L6.84294 7.8183C6.89253 7.83663 6.94504 7.8458 6.9979 7.84538C7.05076 7.84564 7.10324 7.83647 7.15287 7.8183L13.6876 5.51247C13.8716 5.44797 13.9962 5.27589 13.9999 5.08094C14.0037 4.88599 13.8858 4.70926 13.7044 4.63774H13.7082Z"
      fill={fill}
    />
    <path
      d="M10.5771 7.5998L7.15287 8.8134C7.05229 8.84892 6.94258 8.84892 6.842 8.8134L3.42245 7.5998C3.27957 7.54951 3.12117 7.57168 2.99759 7.65927C2.87401 7.74685 2.80061 7.88896 2.80072 8.04043V10.3743C2.80097 10.5721 2.92591 10.7483 3.11252 10.814L5.91313 11.8035C6.61671 12.0512 7.38377 12.0512 8.08734 11.8035L10.888 10.814C11.0746 10.7483 11.1995 10.5721 11.1998 10.3743V8.04043C11.1999 7.88896 11.1265 7.74685 11.0029 7.65927C10.8793 7.57168 10.7209 7.54951 10.578 7.5998H10.5771Z"
      fill={fill}
    />
  </svg>
);
