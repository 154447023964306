import { FC, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DeleteSharp, EditSharp } from '@mui/icons-material';
import { DataGrid, GridActionsCellItem, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import { ConfirmationDialog } from '@/components/Shared/ConfirmationDialog';
import { OrganizationForm, OrganizationFormState } from '../OrganizationForm';

import { Organization, useDeleteOrganization, useUpdateOrganization } from '../../hooks';
import { useGridSortingAndFiltering } from '../../hooks/useGridSortingAndFiltering';

import { gridStyleOverrides } from './utils';

import styles from './styles.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RowActions = (params: GridRenderCellParams<any, Organization, any>) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showEditOrganization, setShowEditOrganization] = useState(false);

  const { mutate: deleteOrganization } = useDeleteOrganization();
  const { mutate: updateOrganization } = useUpdateOrganization();

  const handleEditOrganization = async (organizationFormState: OrganizationFormState) => {
    updateOrganization({ organizationFormState });
    setShowEditOrganization(false);
  };

  return (
    <>
      <div className={styles['row-actions-cell']}>
        <GridActionsCellItem
          onClick={() => setShowEditOrganization(true)}
          icon={<EditSharp />}
          label="Edit"
          title="Edit"
        />
        <GridActionsCellItem
          onClick={() => setShowDeleteConfirmation(true)}
          icon={<DeleteSharp />}
          label="Delete"
          title="Delete"
        />
        {showDeleteConfirmation && (
          <ConfirmationDialog
            title="Confirm delete"
            message={`Are you sure you want to delete the organization ${params.row.name}?`}
            onConfirm={() => deleteOrganization(params.row.id)}
            onCancel={() => setShowDeleteConfirmation(false)}
          />
        )}
        {showEditOrganization && (
          <OrganizationForm
            organization={params.row}
            onCancel={() => setShowEditOrganization(false)}
            onSubmit={handleEditOrganization}
          />
        )}
      </div>
    </>
  );
};

const getColumns = (redirectTo: string): GridColDef<Organization>[] => {
  return [
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      editable: false,
      renderCell: (params) => (
        <Link to={`${params.row.id}`} state={redirectTo}>
          {params.value}
        </Link>
      ),
      headerClassName: styles['data-grid-header'],
    },
    {
      field: 'organizationStatus',
      headerName: 'Status',
      width: 100,
      editable: false,
      headerClassName: styles['data-grid-header'],
    },
    {
      field: 'createdAt',
      headerName: 'Created at',
      type: 'date',
      width: 150,
      editable: false,
      renderCell: (params) => (params.value ? dayjs(params.value).format('DD/MM/YYYY') : ''),
      headerClassName: styles['data-grid-header'],
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 400,
      editable: false,
      headerClassName: styles['data-grid-header'],
    },

    {
      field: 'actions',
      type: 'actions',
      width: 100,
      renderCell: RowActions,
      headerClassName: styles['data-grid-header'],
    },
  ];
};

export type OrganizationsGrid = {
  organizations: Organization[];
};

export const OrganizationsGrid: FC<OrganizationsGrid> = ({ organizations }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tableKey, setTableKey] = useState(0);

  const redirectTo = `${location.pathname}${location.search}`;

  const { sortModelChange, filterModelChange, sortModel, filterModel } = useGridSortingAndFiltering({
    field: 'name',
    sort: 'asc',
  });

  useEffect(() => {
    // if location changes and there isn't any search params then we want to reset the filters
    if (location.search === '') {
      const newTableKey = tableKey + 1;
      setTableKey(newTableKey);
    }
  }, [location]);

  return (
    <DataGrid
      key={tableKey}
      sx={gridStyleOverrides}
      onRowDoubleClick={(params) => navigate(`${params.row.id}`, { state: redirectTo })}
      rows={organizations}
      componentsProps={{
        row: { className: styles['data-grid-row'] },
        filterPanel: {
          filterFormProps: {
            deleteIconProps: {
              sx: {
                width: 'initial', // Weird fix needed for the filter panel
              },
            },
          },
        },
      }}
      rowHeight={42}
      headerHeight={42}
      columns={getColumns(redirectTo)}
      initialState={{
        sorting: {
          sortModel: [{ ...sortModel }],
        },
        filter: {
          filterModel: { items: [{ ...filterModel }] },
        },
      }}
      onSortModelChange={sortModelChange}
      onFilterModelChange={filterModelChange}
      autoPageSize={true}
      disableSelectionOnClick
      disableColumnSelector
    />
  );
};
