import { SyntheticEvent, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { Stack } from '@mui/material';

import { Divider } from '@/components/Shared/Divider';
import { NameMarkerRowNew } from '@/components/Shared/NameMarker';
import { TrblAddMemberIcon, TrblNotebookIcon, TrblSpaceIcon } from '@/components/Icons';
import { ManageAccessPopup } from '@/components/ProjectsView/components/ManageAccessPopup';
import { ProjectActionsMenu } from '../ProjectsPage/ProjectActionsMenu';
import projectFolderImage from './images/project_folder.png';
import { ProjectGeometryImport } from './ProjectGeometryImport';

import { ProjectAndSpacesDto } from '@/types';

import classes from './styles.module.scss';

export const ProjectInfoSidepanel = ({
  project,
  loggedInUserId,
}: {
  project: ProjectAndSpacesDto;
  loggedInUserId: string;
}) => {
  const queryClient = useQueryClient();

  const [showMembersPopup, setShowMembersPopup] = useState(false);

  const openMembers = (event: SyntheticEvent) => {
    event.stopPropagation();
    setShowMembersPopup(true);
  };

  const closeManageAccessPopup = (projectUpdated?: boolean) => {
    setShowMembersPopup(false);

    if (projectUpdated) {
      queryClient.invalidateQueries(['projectById', project.id]);
    }
  };
  const getCreatedByName = () => {
    const member = project.projectUsers.find((user) => user.id == project.createdBy)!;
    return member.firstName ? member.firstName + (member.lastName ? ' ' + member.lastName : '') : member.email;
  };

  return (
    <>
      <div className={classes.project_info_sidepanel}>
        <div className={classes.project_info_row}>
          <Stack flexDirection="row" justifyContent="space-between" gap="6px">
            <div className={classes.project_info_name}>{project.name}</div>

            <ProjectActionsMenu project={project} classNames={classes.project_menu} />
          </Stack>
          <img className={classes.project_info_thumbnail} src={projectFolderImage} alt="model image" />

          <Stack flexDirection="row" justifyContent="space-between">
            <p>Created: {dayjs(project.createdAt).format('DD MMM YYYY')}</p>
            <p>
              <TrblSpaceIcon fill="#DADADA" />
              {project.spaces.length} spaces
            </p>
          </Stack>
          <p>Created by: {getCreatedByName()}</p>
          <p>Last modified: {dayjs(project.updatedAt).format('DD MMM YYYY, HH:mm')}</p>
        </div>

        <Divider />
        <div className={classes.project_info_row}>
          <div className={classes.title}> {project.projectUsers.length > 1 && 'Shared with'}</div>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            {project.projectUsers.length > 1 && (
              <NameMarkerRowNew
                projectUsers={project.projectUsers}
                loggedInUserId={loggedInUserId == project.createdBy ? loggedInUserId : project.createdBy}
                creatorId={project.createdBy}
                background="#272727"
                size="medium"
              />
            )}

            <button className={classes.share_btn} onClick={openMembers}>
              <TrblAddMemberIcon width="22" height="22" fill="#dadada" /> Share
            </button>
          </Stack>
        </div>

        <Divider />
        <div className={classes.project_info_row}>
          <div className={classes.title}>
            <TrblNotebookIcon width="14" height="14" /> Description
          </div>
          <p style={{ color: '#adadad' }}>{project.description || <i>No description</i>}</p>
        </div>

        <div className={classes.project_info_row} style={{ height: '196px' }}>
          <ProjectGeometryImport projectId={project.id} projectName={project.name} />
        </div>
      </div>

      {showMembersPopup && (
        <ManageAccessPopup
          onClose={closeManageAccessPopup}
          project={{ ...project, spacesCount: project.spaces.length }}
        />
      )}
    </>
  );
};
