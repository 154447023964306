import { useState } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { ConfirmationDialog } from '@/components/Shared/ConfirmationDialog';
import { EditSpacePopup, InfoSpacePopup } from '@/components/ProjectsView/components';
import { TrblActionsMenu } from '@/components/TrblActionsMenu';

import { useDeleteSpace } from '@/hooks';

import { IActions, Info, SpaceDetailsDto, SpaceDto } from '@/types';

export const SpaceActionsMenu = ({
  space,
  setSelected,
  classNames,
  onUpdate,
}: {
  space: SpaceDetailsDto;
  setSelected?: (value: boolean) => void;
  classNames?: string;
  onUpdate?: () => void;
}) => {
  const queryClient = useQueryClient();

  const deleteSpace = useDeleteSpace();

  const [spaceInfo, setSpaceInfo] = useState<Info>({} as Info);

  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const getSpaceActions = (space: SpaceDetailsDto): IActions[] => [
    {
      value: 'Edit',
      onClick: () => {
        setShowEditPopup(true);
      },
      key: 'edit-space',
    },
    {
      value: 'Details',
      onClick: () => {
        setSpaceInfo({
          id: space.id,
          name: space.name,
          description: space.description,
          createdAt: space.createdAt,
          createdBy: space.createdByUserEmail,
          elementCount: space.modelCount,
        });
        setShowDetailsPopup(true);
      },
      key: 'details-space',
    },
    {
      value: 'Delete',
      onClick: () => {
        setShowDeleteDialog(true);
      },
      key: 'delete-space',
    },
  ];

  const deleteSpaceFunc = (id: string, space: SpaceDetailsDto) => {
    deleteSpace.mutate(
      { spaceId: id },
      {
        onSuccess: () => {
          if (onUpdate) {
            onUpdate();
          } else {
            queryClient.invalidateQueries(['spaces-for-project', space.projectId]);
          }

          toast.info("'" + space.name + "' deleted");
        },
        onError: () => {
          toast.error('An error occurred while deleting Space');
        },
      }
    );
  };

  const handleClosePopup = () => {
    setShowEditPopup(false);
    setShowDetailsPopup(false);
    setShowDeleteDialog(false);
    if (setSelected) setSelected(false);
  };

  const handleSpaceUpdated = (updatedSpace: SpaceDto) => {
    setShowEditPopup(false);
    if (onUpdate) {
      onUpdate();
    } else {
      queryClient.invalidateQueries(['spaces-for-project', updatedSpace.projectId]);
    }
  };

  return (
    <>
      <TrblActionsMenu
        classNames={classNames}
        actions={getSpaceActions(space)}
        id={space.id}
        title="View space actions"
        size="large"
      />
      {showEditPopup && (
        <EditSpacePopup
          onClose={handleClosePopup}
          onUpdate={(response) => handleSpaceUpdated(response)}
          name={space?.name ?? ''}
          description={space?.description ?? ''}
          id={space?.id ?? ''}
        />
      )}
      {showDetailsPopup && <InfoSpacePopup info={spaceInfo} onClose={handleClosePopup} />}

      {showDeleteDialog && (
        <ConfirmationDialog
          title={`Delete space`}
          message={() => (
            <span>
              Are you sure you want to delete <b>{space.name}</b> ?
            </span>
          )}
          onConfirm={() => {
            deleteSpaceFunc(space.id, space);
            setShowDeleteDialog(false);
          }}
          onCancel={handleClosePopup}
        />
      )}
    </>
  );
};
