import { TrblToggle } from '@/components/Shared';
import { Text } from '@/components/Shared/Text';

import styles from './styles.module.scss';

interface InterpolatedToggleProps {
  isInterpolated: boolean;
  setIsInterpolated: (isInterpolated: boolean) => void;
}

export const InterpolatedToggle = ({ isInterpolated, setIsInterpolated }: InterpolatedToggleProps) => {
  return (
    <div className={styles['interpolate-toggle']}>
      <Text type="regular-11px">Interpolated</Text>
      <TrblToggle
        ariaLabel="Interpolation toggle"
        checked={isInterpolated}
        onChangeToggle={() => setIsInterpolated(!isInterpolated)}
      />
    </div>
  );
};
