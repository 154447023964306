import { createContext, ReactNode, useContext, useEffect, useReducer, useState } from 'react';

import { ResultPresetDto } from '@/components/ResultPresets/types';
import { ParsedResponseData } from '../components/ResponsePlot/types';

import { useGetAllSimulationsForSpaces } from '../hooks/useGetAllSimulationsForSpaces';

import { comparisonsColors, MAX_COMPARISONS } from '../constants';

import { NewComparison, ReceiverSquare, ResultComparison, SpaceSimulationsDto } from '../types';

type ResultsProviderProps = { children: ReactNode; isInResultsMode?: boolean };

let intervalId: NodeJS.Timeout | undefined = undefined;
let startFrequency = 0;

enum ActionType {
  SET_SELECTED_COMPARISON_INDEX = 'SET_SELECTED_COMPARISON_INDEX',
  ADD_COMPARISON = 'ADD_COMPARISON',
  REMOVE_COMPARISON = 'REMOVE_COMPARISON',
  UPDATE_COMPARISON = 'UPDATE_COMPARISON',
  UPDATE_COMPARISON_ORDER = 'UPDATE_COMPARISON_ORDER',
  RESET_STATE = 'RESET_STATE',
  SET_SURFACE_RECEIVERS_IS_INTERPOLATED = 'SET_SURFACE_RECEIVERS_IS_INTERPOLATED',
  SET_SURFACE_RECEIVERS_PARAMETER = 'SET_SURFACE_RECEIVERS_PARAMETER',
  SET_SURFACE_RECEIVERS_FREQUENCY = 'SET_SURFACE_RECEIVERS_FREQUENCY',
  SET_SURFACE_RECEIVERS_NC_CURVE = 'SET_SURFACE_RECEIVERS_NC_CURVE',
  SET_TARGET_VALUE = 'SET_TARGET_VALUE',
  SET_TARGET_VALUE_MAX = 'SET_TARGET_VALUE_MAX',
  SET_TARGET_TYPE = 'SET_TARGET_TYPE',
  SET_TARGET_PERCENTAGE = 'SET_TARGET_PERCENTAGE',
  SET_EMPTY_RESULTS = 'SET_EMPTY_RESULTS',
  SET_SELECTED_PRESET = 'SET_SELECTED_PRESET',
  SET_PRESET_EDITED = 'SET_PRESET_EDITED',
  SET_AVAILABLE_PRESETS = 'SET_AVAILABLE_PRESETS',
  SET_RESPONSE_DATA = 'SET_RESPONSE_DATA',
  SHOW_FREQ_RESPONSE_POPUP = 'SHOW_FREQ_RESPONSE_POPUP',
  SET_GRID_RECEIVER_SUB_VIEW = 'SET_GRID_RECEIVER_SUB_VIEW',
  SET_MODAL_FREQUENCY_SELECTED = 'SET_MODAL_FREQUENCY_SELECTED',
  SET_MODAL_ANALYSIS_DATA = 'SET_MODAL_ANALYSIS_DATA',
  SET_IS_PLAYING_MODAL_ANALYSIS = 'SET_IS_PLAYING_MODAL_ANALYSIS',
  SET_MODAL_ANALYSIS_PARAMETER = 'SET_MODAL_ANALYSIS_PARAMETER',
  SET_MODAL_ANALYSIS_RECEIVER_DATA = 'SET_MODAL_ANALYSIS_RECEIVER_DATA',
  SET_SELECTED_RECEIVER_SQUARE = 'SET_SELECTED_RECEIVER_SQUARE',
  SET_MODAL_ANALYSIS_FREQUENCY_VALUES = 'SET_MODAL_ANALYSIS_FREQUENCY_VALUES',
}

type ResultsContextAction =
  | { type: ActionType.RESET_STATE }
  | { type: ActionType.SET_SELECTED_COMPARISON_INDEX; selectedComparisonIndex: number }
  | { type: ActionType.ADD_COMPARISON; newComparison: NewComparison; select: boolean }
  | { type: ActionType.REMOVE_COMPARISON; color: string }
  | { type: ActionType.UPDATE_COMPARISON; update: ResultComparison }
  | { type: ActionType.UPDATE_COMPARISON_ORDER; oldIndex: number; newIndex: number }
  | { type: ActionType.SET_SURFACE_RECEIVERS_IS_INTERPOLATED; isInterpolated: boolean }
  | { type: ActionType.SET_SURFACE_RECEIVERS_PARAMETER; parameter: string | null }
  | { type: ActionType.SET_SURFACE_RECEIVERS_FREQUENCY; frequency: string | null }
  | { type: ActionType.SET_SURFACE_RECEIVERS_NC_CURVE; payload: string | null }
  | { type: ActionType.SET_TARGET_VALUE; payload: number | undefined }
  | { type: ActionType.SET_TARGET_VALUE_MAX; payload: number | undefined }
  | { type: ActionType.SET_TARGET_TYPE; payload: string }
  | { type: ActionType.SET_TARGET_PERCENTAGE; payload: number }
  | {
      type: ActionType.SET_EMPTY_RESULTS;
      emptyResults: boolean;
    }
  | {
      type: ActionType.SET_SELECTED_PRESET;
      preset: ResultPresetDto | null;
    }
  | {
      type: ActionType.SET_PRESET_EDITED;
      presetEdited: boolean;
    }
  | {
      type: ActionType.SET_AVAILABLE_PRESETS;
      presets: ResultPresetDto[];
    }
  | {
      type: ActionType.SET_RESPONSE_DATA;
      responseData: ParsedResponseData[];
    }
  | {
      type: ActionType.SHOW_FREQ_RESPONSE_POPUP;
      showFreqResponsePopup: boolean;
    }
  | {
      type: ActionType.SET_GRID_RECEIVER_SUB_VIEW;
      gridReceiverSubView: 'grid' | 'modal';
    }
  | {
      type: ActionType.SET_MODAL_FREQUENCY_SELECTED;
      modalFrequencySelected: number;
    }
  | {
      type: ActionType.SET_IS_PLAYING_MODAL_ANALYSIS;
      isPlayingModalAnalysis: boolean;
    }
  | { type: ActionType.SET_MODAL_ANALYSIS_PARAMETER; parameter: string }
  | {
      type: ActionType.SET_MODAL_ANALYSIS_RECEIVER_DATA;
      modalAnalysisReceiverData: Record<string, Record<string, number[]>> | null;
    }
  | { type: ActionType.SET_SELECTED_RECEIVER_SQUARE; receiverSquare: ReceiverSquare | null }
  | {
      type: ActionType.SET_MODAL_ANALYSIS_FREQUENCY_VALUES;
      modalAnalysisFrequencyValues: Record<string, number[]> | null;
    };

type State = {
  selectedComparisonIndex: number;
  availableComparisons: ResultComparison[];
  surfaceReceiversIsInterpolated: boolean;
  surfaceReceiversSelectedParameter: string | null;
  surfaceReceiversSelectedFrequency: string | null;
  surfaceReceiversSelectedNcCurve: string | null;
  targetValue: number | undefined;
  targetValueMax: number | undefined;
  targetType: string;
  targetPercentage: number;
  hasAddedComparisons: boolean;
  dispatch: (action: ResultsContextAction) => void;
  allSpacesWithSims: SpaceSimulationsDto[];
  selectedPreset: ResultPresetDto | null;
  selectedPresetEdited: boolean;
  availablePresets: ResultPresetDto[];
  emptyResults: boolean;
  responseData: ParsedResponseData[];
  showFreqResponsePopup: boolean;
  gridReceiverSubView: 'grid' | 'modal';
  modalFrequencySelected: number;
  isPlayingModalAnalysis: boolean;
  modalAnalysisSelectedParameter: string;
  modalAnalysisReceiverData: Record<string, Record<string, number[]>> | null;
  receiverSquare: ReceiverSquare | null;
  modalAnalysisFrequencyValues: Record<string, number[]> | null;
};

const DEFAULT_MODAL_FREQUENCY = 50;

const initialState: State = {
  selectedComparisonIndex: 0,
  availableComparisons: [],
  surfaceReceiversIsInterpolated: true,
  surfaceReceiversSelectedParameter: null,
  surfaceReceiversSelectedFrequency: null,
  surfaceReceiversSelectedNcCurve: null,
  targetValue: undefined,
  targetValueMax: undefined,
  targetType: 'Above',
  targetPercentage: 0,
  hasAddedComparisons: false,
  dispatch: () => {},
  allSpacesWithSims: [] as SpaceSimulationsDto[],
  selectedPreset: null,
  selectedPresetEdited: false,
  availablePresets: [],
  emptyResults: false,
  responseData: [],
  showFreqResponsePopup: false,
  gridReceiverSubView: 'grid',
  modalFrequencySelected: DEFAULT_MODAL_FREQUENCY,
  isPlayingModalAnalysis: false,
  modalAnalysisSelectedParameter: 'spl',
  modalAnalysisReceiverData: null,
  receiverSquare: null,
  modalAnalysisFrequencyValues: null,
};

const ResultsContext = createContext(initialState);

function handleUnknownAction(action: never): never;
function handleUnknownAction(action: ResultsContextAction) {
  throw new Error(`Unhandled action type: ${action.type}`);
}

const resultsReducer = (state: State, action: ResultsContextAction): State => {
  switch (action.type) {
    case ActionType.RESET_STATE: {
      return {
        ...initialState,
      };
    }

    case ActionType.SET_SURFACE_RECEIVERS_IS_INTERPOLATED: {
      return {
        ...state,
        surfaceReceiversIsInterpolated: action.isInterpolated,
      };
    }

    case ActionType.SET_SURFACE_RECEIVERS_FREQUENCY: {
      return {
        ...state,
        surfaceReceiversSelectedFrequency: action.frequency,
      };
    }

    case ActionType.SET_SURFACE_RECEIVERS_PARAMETER: {
      return {
        ...state,
        surfaceReceiversSelectedParameter: action.parameter,
      };
    }

    case ActionType.SET_SURFACE_RECEIVERS_NC_CURVE: {
      return {
        ...state,
        surfaceReceiversSelectedNcCurve: action.payload,
      };
    }

    case ActionType.SET_SELECTED_COMPARISON_INDEX: {
      return {
        ...state,
        selectedComparisonIndex: action.selectedComparisonIndex,
      };
    }

    case ActionType.SET_TARGET_VALUE: {
      return {
        ...state,
        targetValue: action.payload,
      };
    }

    case ActionType.SET_TARGET_VALUE_MAX: {
      return {
        ...state,
        targetValueMax: action.payload,
      };
    }

    case ActionType.SET_TARGET_TYPE: {
      return {
        ...state,
        targetType: action.payload,
      };
    }

    case ActionType.SET_TARGET_PERCENTAGE: {
      return {
        ...state,
        targetPercentage: action.payload,
      };
    }

    case ActionType.ADD_COMPARISON: {
      const {
        selectedSimulation,
        modelName,
        spaceName,
        title,
        resultType,
        sourcesSelected,
        receiversSelected,
        surfaceReceiversSelected,
        color,
      } = action.newComparison;
      const select = action.select;
      const index = state.availableComparisons.length;

      // There can only be max 6 comparisons
      if (index < MAX_COMPARISONS) {
        const colorsInUse = state.availableComparisons.map((comp) => comp.color);
        const nextColor = comparisonsColors.filter((color) => !colorsInUse.includes(color));

        const newComparison: ResultComparison = {
          color: color ?? nextColor[0],
          formState: {
            simulationId: selectedSimulation.id,
            selectedSimulation: selectedSimulation,
            lastSourceSummingResults: null,
            lastGridReceiverSourceSummingResults: null,
            lastSelectedResultType: '',
            resultType: resultType ?? '',
            title,
            modelName,
            spaceName,
            sourcePointIds: sourcesSelected ?? [],
            receiverPointIds: receiversSelected?.length ? receiversSelected : undefined,
            gridReceiverPointIds: surfaceReceiversSelected,
            color,
            sourceSummingSelectionEnabled: false,
            selectedSourcesForSumming: [],
          },
        };

        const newComparisons = [...state.availableComparisons, newComparison];

        return {
          ...state,
          hasAddedComparisons: true,
          availableComparisons: newComparisons,
          selectedComparisonIndex: select ? newComparisons.length - 1 : state.selectedComparisonIndex,
        };
      }
      return { ...state };
    }

    case ActionType.REMOVE_COMPARISON: {
      const color = action.color;
      const newComparisons = [...state.availableComparisons];
      const deletedComparisonIndex = newComparisons.findIndex((comp) => comp.color === color);
      if (deletedComparisonIndex > -1) {
        newComparisons.splice(deletedComparisonIndex, 1);
      }

      return {
        ...state,
        availableComparisons: newComparisons,
        selectedComparisonIndex:
          state.selectedComparisonIndex === deletedComparisonIndex
            ? 0
            : state.selectedComparisonIndex > deletedComparisonIndex
            ? state.selectedComparisonIndex - 1
            : state.selectedComparisonIndex,
      };
    }

    case ActionType.UPDATE_COMPARISON: {
      const { color, formState } = action.update;
      const index = state.availableComparisons.findIndex((comp) => comp.color === color);
      if (index !== -1) {
        const newComparisons = [...state.availableComparisons];
        newComparisons[index] = {
          color,
          formState,
        };

        return {
          ...state,
          availableComparisons: [...newComparisons],
        };
      }

      return {
        ...state,
      };
    }

    case ActionType.UPDATE_COMPARISON_ORDER: {
      const { oldIndex, newIndex } = action;
      const newComparisons = [...state.availableComparisons];
      const [removed] = newComparisons.splice(oldIndex, 1);
      newComparisons.splice(newIndex, 0, removed);

      let selectedComparisonIndex = state.selectedComparisonIndex;
      if (oldIndex === selectedComparisonIndex) {
        selectedComparisonIndex = newIndex;
      } else if (oldIndex < selectedComparisonIndex && newIndex >= selectedComparisonIndex) {
        selectedComparisonIndex--;
      } else if (oldIndex > selectedComparisonIndex && newIndex <= selectedComparisonIndex) {
        selectedComparisonIndex++;
      }

      return {
        ...state,
        availableComparisons: newComparisons,
        selectedComparisonIndex,
      };
    }

    case ActionType.SET_SELECTED_PRESET: {
      return {
        ...state,
        selectedPreset: action.preset,
      };
    }

    case ActionType.SET_PRESET_EDITED: {
      return {
        ...state,
        selectedPresetEdited: action.presetEdited,
      };
    }

    case ActionType.SET_AVAILABLE_PRESETS: {
      return {
        ...state,
        availablePresets: [...action.presets],
      };
    }

    case ActionType.SET_EMPTY_RESULTS: {
      return {
        ...state,
        emptyResults: action.emptyResults,
      };
    }

    case ActionType.SET_RESPONSE_DATA: {
      const newResponseData = [...state.responseData, ...action.responseData];
      return {
        ...state,
        responseData: newResponseData,
      };
    }

    case ActionType.SHOW_FREQ_RESPONSE_POPUP: {
      return {
        ...state,
        showFreqResponsePopup: action.showFreqResponsePopup,
      };
    }

    case ActionType.SET_GRID_RECEIVER_SUB_VIEW: {
      return {
        ...state,
        gridReceiverSubView: action.gridReceiverSubView,
      };
    }

    case ActionType.SET_MODAL_FREQUENCY_SELECTED: {
      return {
        ...state,
        modalFrequencySelected: action.modalFrequencySelected,
      };
    }

    case ActionType.SET_IS_PLAYING_MODAL_ANALYSIS: {
      return {
        ...state,
        isPlayingModalAnalysis: action.isPlayingModalAnalysis,
      };
    }

    case ActionType.SET_MODAL_ANALYSIS_PARAMETER: {
      return {
        ...state,
        modalAnalysisSelectedParameter: action.parameter,
      };
    }

    case ActionType.SET_MODAL_ANALYSIS_RECEIVER_DATA: {
      return {
        ...state,
        modalAnalysisReceiverData: action.modalAnalysisReceiverData,
      };
    }

    case ActionType.SET_SELECTED_RECEIVER_SQUARE: {
      return {
        ...state,
        receiverSquare: action.receiverSquare,
      };
    }

    case ActionType.SET_MODAL_ANALYSIS_FREQUENCY_VALUES: {
      return {
        ...state,
        modalAnalysisFrequencyValues: action.modalAnalysisFrequencyValues,
      };
    }

    default: {
      handleUnknownAction(action);
    }
  }
};

const ResultsProvider = ({ children, isInResultsMode }: ResultsProviderProps) => {
  const [state, dispatch] = useReducer(resultsReducer, initialState);
  const [allSpacesWithSims, setAllSpacesWithSims] = useState<SpaceSimulationsDto[]>([] as SpaceSimulationsDto[]);

  // Not triggered on render, but only when entering results mode
  const { data: allSimulationsForSpaces, refetch } = useGetAllSimulationsForSpaces(false);

  useEffect(() => {
    if (allSimulationsForSpaces && allSimulationsForSpaces.length > 0) {
      getAllSimulations(allSimulationsForSpaces);
    }
  }, [allSimulationsForSpaces]);

  useEffect(() => {
    if (isInResultsMode) {
      refetch();
    }
  }, [isInResultsMode, refetch]);

  useEffect(() => {
    if (state.isPlayingModalAnalysis) {
      //  Increment right away
      startFrequency = state.modalFrequencySelected;

      dispatch({
        type: ActionType.SET_MODAL_FREQUENCY_SELECTED,
        modalFrequencySelected: startFrequency + 1,
      });
      startFrequency++;

      intervalId = setInterval(() => {
        startFrequency++;
        dispatch({
          type: ActionType.SET_MODAL_FREQUENCY_SELECTED,
          modalFrequencySelected: startFrequency,
        });
      }, 800);
    } else {
      clearInterval(intervalId);
      intervalId = undefined;
    }
  }, [state.isPlayingModalAnalysis]);

  const getAllSimulations = async (allSimulationsForSpaces: SpaceSimulationsDto[]) => {
    const orderedSpaces = [...allSimulationsForSpaces];
    orderedSpaces.sort((spaceSimA: SpaceSimulationsDto, spaceSimB: SpaceSimulationsDto) => {
      const projectNameA = spaceSimA.projectName.toLowerCase();
      const projectNameB = spaceSimB.projectName.toLowerCase();
      return projectNameA < projectNameB ? -1 : projectNameA > projectNameB ? 1 : 0;
    });
    orderedSpaces.forEach((space) => {
      space.simulations.sort((simA, simB) => {
        return new Date(simB.createdAt) > new Date(simA.createdAt) ? 1 : -1;
      });
    });
    setAllSpacesWithSims(orderedSpaces);
  };

  const value = { ...state, allSpacesWithSims, dispatch };

  return <ResultsContext.Provider value={value}>{children}</ResultsContext.Provider>;
};

// Custom Context hook to easily access the state and dispatch actions
const useResultsContext = () => {
  const context = useContext(ResultsContext);
  if (context === undefined) {
    throw new Error('useResultsContext must be used within ResultsProvider');
  }
  return context;
};

export { ActionType, ResultsProvider, useResultsContext };
