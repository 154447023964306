import { ReactNode, SyntheticEvent, useRef, useState } from 'react';

import { ClickAwayListener, Popper, PopperPlacementType } from '@mui/material';

import { TrblChevronDownIcon } from '@/components/Icons';

import classes from './styles.module.scss';

interface TrblCustomSelectProps {
  children: ReactNode;
  selectedItem: ReactNode;
  placement?: PopperPlacementType;
}

export const TrblCustomSelect = ({ children, selectedItem, placement = 'bottom-start' }: TrblCustomSelectProps) => {
  const dropdownBtn = useRef<HTMLButtonElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleOpenDropdown = (e: SyntheticEvent) => {
    e.preventDefault();
    setShowDropdown(true);
  };

  return (
    <>
      <button
        ref={dropdownBtn}
        className={`${classes.dropdown_btn} ${showDropdown ? classes.selected : ''} `}
        onClick={(e) => handleOpenDropdown(e)}>
        <div className={classes.selected_item}>{selectedItem}</div>
        <TrblChevronDownIcon width="7" height="5" fill="#999999" />
      </button>

      {showDropdown && (
        <Popper
          sx={{ zIndex: 1300 }}
          placement={placement}
          open={showDropdown}
          anchorEl={dropdownBtn.current}
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}>
          <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
            <div className={classes.dropdown_content}>{children}</div>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  );
};
