import { useState } from 'react';

import { useBaseContext } from '@/context/BaseContext';

import { TrblTooltip } from '@/components/Shared';
import { TrblAddIcon } from '@/components/Icons';
import { PageLayout } from '@/components/New/PageLayout';
import { PageHeader } from '../PageHeader/PageHeader';
import { ProjectCard } from './ProjectCard';

import { useGetProjectsAndSpaces } from '@/hooks/Project/useGetProjectsAndSpaces';

import { ProjectAndSpacesDto, SpaceDetailsDto } from '@/types';

import classes from './styles.module.scss';

const projectsWithSpaces: Record<string, SpaceDetailsDto[]> = {};

export const ProjectsPage = ({ projectFilter }: { projectFilter: string }) => {
  const {
    state: { userInfo },
  } = useBaseContext();

  const { data: availableProjects, isLoading } = useGetProjectsAndSpaces(projectFilter);

  const [projectSearchString] = useState('');

  const viewType = 'cards';
  const cardStyling = viewType === 'cards' ? classes.projects_cards_container : classes.projects_list_container;

  const filterProject = (project: ProjectAndSpacesDto) => {
    if (project.isDeleted) return false;

    let showProject = false;

    // if no search string then show the projects
    // or if there is a search string and it's included in the project or space name , then also show project
    if (
      projectSearchString == '' ||
      project.name.toLowerCase().includes(projectSearchString.toLowerCase()) ||
      projectsWithSpaces[project.id].find((y: SpaceDetailsDto) =>
        y.name.toLowerCase().includes(projectSearchString.toLowerCase())
      )
    ) {
      showProject = true;
    }

    return showProject;
  };

  return (
    <PageLayout isFetching={isLoading} navpanel={true}>
      <div className={classes.projects_content}>
        <PageHeader />
        <div className={cardStyling}>
          {userInfo &&
            availableProjects &&
            availableProjects
              .filter((project) => filterProject(project))
              .map((project) => (
                <ProjectCard
                  key={project.id}
                  project={project}
                  spaces={project.spaces.filter((space) => space.modelCount > 0)}
                  loggedInUserId={userInfo.id}
                  projectSearchString={projectSearchString}
                />
              ))}
          <TrblTooltip title="Create new project">
            <button className={classes.create_project_btn}>
              <TrblAddIcon fill="none" width="54" height="54" props="0.65" />
            </button>
          </TrblTooltip>
        </div>
      </div>
    </PageLayout>
  );
};
