import { useEffect, useState } from 'react';

import { ActionType, useCreateMaterialContext } from '../context/CreateMaterialContext';
import { useBaseContext } from '@/context/BaseContext';

import { TrblToggle, TrblTooltip } from '@/components/Shared';
import { SecondaryButton } from '@/components/Shared/Buttons';
import { ShareDisabledInformation } from '@/components/Shared/ShareDisabledInformation';
import { BuilderMaterialFit } from './BuilderMaterialFit';
import { CreateMaterialButton } from './CreateMaterialButton';
import { LambdaMaterialFit } from './LambdaMaterialFit';
import { SimpleMaterialFit } from './SimpleMaterialFit';

import { MaterialInputType } from '../constants';

export const CreateMaterialActions = ({
  setShowPopup,
}: {
  setShowPopup: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}) => {
  const {
    formDisabled,
    impedanceFittedData,
    reflectionFittedData,
    builderFittedData,
    materialFit,
    inputType,
    shouldShare,
    impedanceData,
    reflectionData,
    dispatch,
  } = useCreateMaterialContext();
  const [hasFittedData, setHasFittedData] = useState<boolean>(false);
  const {
    state: { subscriptionInfo },
  } = useBaseContext();

  useEffect(() => {
    setHasFittedData(
      impedanceFittedData !== null ||
        reflectionFittedData !== null ||
        builderFittedData !== null ||
        materialFit !== null
    );
  }, [impedanceFittedData, reflectionFittedData, builderFittedData, materialFit]);

  const resetForm = () => {
    dispatch({
      type: ActionType.RESET_FORM,
    });
  };

  const editForm = () => {
    dispatch({
      type: ActionType.EDIT_FORM,
    });
  };

  const setShouldShare = (shouldShare: boolean) => {
    dispatch({
      type: ActionType.SET_SHARE_MATERIAL,
      shouldShare,
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
      }}>
      <div>
        <TrblTooltip title={!subscriptionInfo?.hasAccessToShare ? <ShareDisabledInformation type="materials" /> : null}>
          <div>
            <TrblToggle
              checked={shouldShare}
              ariaLabel="Share material toggle"
              onChangeToggle={() => setShouldShare(!shouldShare)}
              disabled={!subscriptionInfo?.hasAccessToShare}
            />
            <span style={{ marginLeft: '8px', opacity: !subscriptionInfo?.hasAccessToShare ? 0.7 : 1 }}>
              {' '}
              Share with organization{' '}
            </span>
          </div>
        </TrblTooltip>
      </div>

      <div>
        <SecondaryButton
          style={{ marginRight: '10px' }}
          width={'fit-content'}
          label={hasFittedData ? 'Edit' : 'Reset form'}
          disabled={formDisabled}
          onClick={hasFittedData ? editForm : resetForm}
        />
        {inputType === MaterialInputType.FullOctaveAbsorption && (
          <>
            {materialFit === null && <SimpleMaterialFit />}
            {materialFit !== null && <CreateMaterialButton setShowPopup={setShowPopup} />}
          </>
        )}
        {inputType === MaterialInputType.SurfaceImpedance && (
          <>
            {impedanceFittedData === null && <LambdaMaterialFit data={impedanceData} />}
            {impedanceFittedData !== null && <CreateMaterialButton setShowPopup={setShowPopup} />}
          </>
        )}
        {inputType === MaterialInputType.ReflectionCoefficient && (
          <>
            {reflectionFittedData === null && <LambdaMaterialFit data={reflectionData} />}
            {reflectionFittedData !== null && <CreateMaterialButton setShowPopup={setShowPopup} />}
          </>
        )}
        {inputType === MaterialInputType.PorousAbsorberBuilder && (
          <>
            {builderFittedData === null && <BuilderMaterialFit />}
            {builderFittedData !== null && <CreateMaterialButton setShowPopup={setShowPopup} />}
          </>
        )}
      </div>
    </div>
  );
};
