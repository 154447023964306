import { FC } from 'react';

import { TabButtons } from '@/components/Shared';
import { TrblSearchIcon } from '@/components/Icons';

import styles from './styles.module.scss';

type PageHeaderProps = {
  showSearch?: boolean;
  showSwitcher?: boolean;
};

export const PageHeader: FC<PageHeaderProps> = ({ showSearch = true, showSwitcher = true }) => {
  return (
    <div className={styles['page-header']}>
      {showSearch && (
        <div className={styles['search-container']}>
          <TrblSearchIcon width="16" height="16" fill="#f5f5f5" />
        </div>
      )}
      {showSwitcher && (
        <div className={styles['card-switcher']}>
          <TabButtons
            options={[
              { key: 'cards', icon: 'cardsView', tooltip: 'Card view' },
              { key: 'list', icon: 'listView', tooltip: 'List view' },
            ]}
            selectedValue="cards"
            onChange={() => {}}
            height={30}
            dark
            small
          />
        </div>
      )}
    </div>
  );
};
