export const TrblUserIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ overflow: 'visible' }}>
    <path
      d="M17.5 9C17.5 13.6944 13.6944 17.5 9 17.5C4.30558 17.5 0.5 13.6944 0.5 9C0.5 4.30558 4.30558 0.5 9 0.5C13.6944 0.5 17.5 4.30558 17.5 9Z"
      stroke="#DADADA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 6.42847C9.93198 6.42847 10.6875 5.67295 10.6875 4.74097C10.6875 3.80899 9.93198 3.05347 9 3.05347C8.06802 3.05347 7.3125 3.80899 7.3125 4.74097C7.3125 5.67295 8.06802 6.42847 9 6.42847Z"
      fill="#DADADA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.97573 14.1429L10.2859 11.2156H11.5716V9.75191C11.5716 8.40466 10.4636 7.3125 8.99996 7.3125C7.53631 7.3125 6.42871 8.40466 6.42871 9.75191V11.2156H7.71443L8.02419 14.1429H9.97573Z"
      fill="#DADADA"
    />
  </svg>
);
