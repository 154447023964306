import { useEffect, useMemo, useRef, useState } from 'react';
import { ThreeEvent, useThree } from '@react-three/fiber';
import { Group } from 'three';
import SpriteText from 'three-spritetext';

import { ActionType, useResultsContext } from '@/components/Results/context/ResultsContext';
import { ActionType as EdActionType, useEditorContext } from '@/context/EditorContext';

import { useObjectClickHandler } from '@/components/Editor/hooks';
import { useGetLastModalAnalysisResult } from '@/components/Results/components/ResultComparison/hooks';
import { ColorMapMenu } from './ColorMapMenu';
import { ColorScale } from './ColorScale';
import { Heatmap } from './Heatmap';

import {
  useColorBar,
  useForcedModalAnalysis,
  useParameterValuesForSelectedSource,
  useTargetValueColorBar,
} from './hooks';

import { makeCursorTarget, makePressureText } from './utils';
// @ts-expect-error Could not find a declaration file for module '@/utils/ColorBar'.
import { ColorBar, ColorMapKeywords } from '@/utils/ColorBar';
import { getParameterDecimals, roundFloat } from '@/utils/trebleFunctions';

import { ParameterValuesForSelectedSource } from './types';
import { ResultsView } from '@/context/EditorContext/types';
import { GridReceiver } from '@/types';

let pressureTextArray: SpriteText[] = [];
let parameterDecimals = 1;

export const GridReceiverResults = ({
  isExporting,
  setIsExporting,
}: {
  isExporting: boolean;
  setIsExporting: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const pressureGroup = useRef<THREE.Group>(null);
  const { resultsView, hiddenSurfaceReceivers, dispatch: edDispatch } = useEditorContext();
  const {
    selectedComparisonIndex,
    availableComparisons,
    surfaceReceiversIsInterpolated: isInterpolated,
    surfaceReceiversSelectedParameter: selectedParameter,
    surfaceReceiversSelectedFrequency: selectedFrequency,
    surfaceReceiversSelectedNcCurve: selectedNcCurve,
    gridReceiverSubView,
    modalFrequencySelected,
    isPlayingModalAnalysis,
    modalAnalysisSelectedParameter,
    dispatch,
  } = useResultsContext();

  const [customMin, setCustomMin] = useState<number | null>(null);
  const [customMax, setCustomMax] = useState<number | null>(null);
  const [colorMap, setColorMap] = useState<string>('rainbow');

  const selectedComparison = availableComparisons.length > 0 ? availableComparisons[selectedComparisonIndex] : null;
  const gridReceiverResultsToDisplay = selectedComparison?.formState?.simulationData?.selectedGridReceiverObjects ?? [];
  const selectedSourceObject = selectedComparison?.formState?.simulationData?.selectedSourceObject;

  const selectedSimulationId = selectedComparison?.formState?.simulationId;

  useEffect(() => {
    if (gridReceiverSubView === 'modal') {
      edDispatch({
        type: EdActionType.SET_SHOW_LOADING_SCREEN,
        isLoadingScreen: loading,
      });
    }
  }, [selectedComparisonIndex]);

  useEffect(() => {
    if (gridReceiverSubView === 'grid') {
      edDispatch({
        type: EdActionType.SET_SHOW_LOADING_SCREEN,
        isLoadingScreen: false,
      });
    }
  }, [gridReceiverSubView]);

  const { size, invalidate } = useThree();

  const { data: modalAnalysisResult } = useGetLastModalAnalysisResult(
    selectedSimulationId,
    gridReceiverSubView === 'modal' && !!selectedSimulationId
  );

  const memoizedIds = useMemo(
    () => (gridReceiverResultsToDisplay ? gridReceiverResultsToDisplay.map((grid) => grid.pointId) : []),
    [gridReceiverResultsToDisplay]
  );

  // modal analysis data
  const {
    frequencyData: modalAnalysisData,
    receiverData: modalAnalysisReceiverData,
    frequencyValues,
    minMaxData: modalAnalysisMinMax,
    isLoading: loading,
  } = useForcedModalAnalysis(modalAnalysisResult, memoizedIds, selectedSourceObject);

  useEffect(() => {
    handleOutsideClick();
  }, [selectedComparison]);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_MODAL_ANALYSIS_RECEIVER_DATA,
      modalAnalysisReceiverData,
    });
  }, [modalAnalysisReceiverData]);

  useEffect(() => {
    if (frequencyValues && Object.keys(frequencyValues).length > 0) {
      dispatch({
        type: ActionType.SET_MODAL_ANALYSIS_FREQUENCY_VALUES,
        modalAnalysisFrequencyValues: frequencyValues,
      });
    } else {
      dispatch({
        type: ActionType.SET_MODAL_ANALYSIS_FREQUENCY_VALUES,
        modalAnalysisFrequencyValues: null,
      });
    }
  }, [frequencyValues]);

  // parameter values data
  const parameterValuesForSelectedSource: ParameterValuesForSelectedSource | null =
    useParameterValuesForSelectedSource(selectedComparison);

  useEffect(() => {
    return () => {
      edDispatch({
        type: EdActionType.SET_SHOW_LOADING_SCREEN,
        isLoadingScreen: false,
      });
      dispatch({
        type: ActionType.SET_IS_PLAYING_MODAL_ANALYSIS,
        isPlayingModalAnalysis: false,
      });

      handleOutsideClick();
    };
  }, []);

  useEffect(() => {
    handleOutsideClick();
  }, [gridReceiverSubView]);

  useEffect(() => {
    if (gridReceiverSubView === 'modal') {
      edDispatch({
        type: EdActionType.SET_SHOW_LOADING_SCREEN,
        isLoadingScreen: loading,
      });
    } else {
      edDispatch({
        type: EdActionType.SET_SHOW_LOADING_SCREEN,
        isLoadingScreen: false,
      });
    }
  }, [gridReceiverSubView, loading, selectedComparison]);

  useEffect(() => {
    setCustomMin(null);
    setCustomMax(null);

    parameterDecimals = getParameterDecimals(
      gridReceiverSubView === 'modal' ? modalAnalysisSelectedParameter : selectedParameter!
    );
    if (pressureTextArray.length) {
      updatePressureTexts(pressureTextArray);
    }
  }, [selectedParameter, modalAnalysisSelectedParameter, gridReceiverSubView]);

  useEffect(() => {
    if (pressureTextArray.length) {
      updatePressureTexts(pressureTextArray);
    }
  }, [selectedFrequency, gridReceiverResultsToDisplay]);

  useEffect(() => {
    if (pressureTextArray.length) {
      updatePressureTexts(pressureTextArray);
    }
  }, [modalAnalysisData, modalFrequencySelected]);

  const colorBar: ColorBar = useColorBar(
    customMin,
    customMax,
    parameterValuesForSelectedSource,
    modalAnalysisMinMax,
    gridReceiverResultsToDisplay,
    colorMap
  );

  const targetValueColorBar = useTargetValueColorBar(
    colorBar,
    parameterValuesForSelectedSource,
    gridReceiverResultsToDisplay,
    parameterDecimals
  );

  const updatePressureTexts = (textArray: SpriteText[]) => {
    for (let i = textArray.length - 1; i >= 0; i--) {
      const pressureText = textArray[i];
      const gridVisible = gridReceiverResultsToDisplay.some(
        (visibleGrid) => visibleGrid.pointId === pressureText.userData.pointId
      );

      if (gridVisible) {
        // Select the correct array of values from the list of grid receiver results
        const pointsArray =
          modalAnalysisData?.[pressureText.userData.pointId]?.[modalFrequencySelected] ??
          // @ts-expect-error Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'ParameterValues'.
          parameterValuesForSelectedSource?.[pressureText.userData.pointId]?.[selectedParameter]?.[
            selectedParameter !== 'sti' ? selectedFrequency! : selectedNcCurve!
          ] ??
          [];

        if (pointsArray) {
          const pointValue = pointsArray[pressureText.userData.index];

          dispatch({
            type: ActionType.SET_SELECTED_RECEIVER_SQUARE,
            receiverSquare: {
              index: pressureText.userData.index,
              gridIndex: pressureText.userData.gridIndex,
              pressure: pointValue,
              pointId: pressureText.userData.pointId,
            },
          });

          // Update the text if the value is valid
          if (Math.abs(pointValue) < 150 && isFinite(pointValue)) {
            pressureText.text = roundFloat(pointValue, parameterDecimals).toString();
          }
        }

        // Adjust scale
        pressureText.scale.set(pressureText.scale.x / size.height, pressureText.scale.y / size.height, 0);
      } else {
        textArray.splice(i, 1);
        if (pressureGroup.current) {
          pressureGroup.current.children.splice(i, 1);
        }
      }
    }

    // Re-render canvas
    invalidate();
  };

  const clickHandlerProps = useObjectClickHandler((event: ThreeEvent<PointerEvent>) => {
    const square = event.object;

    if (!event.ctrlKey || gridReceiverSubView === 'modal') {
      if (pressureGroup.current) {
        pressureGroup.current.children = [];
      }
      pressureTextArray = [];
    }

    if (gridReceiverSubView === 'modal') {
      dispatch({
        type: ActionType.SET_SELECTED_RECEIVER_SQUARE,
        receiverSquare: {
          index: square.userData.index,
          gridIndex: square.userData.gridIndex,
          pressure: square.userData.pressure,
          pointId: square.parent?.userData.pointId,
        },
      });
      dispatch({
        type: ActionType.SHOW_FREQ_RESPONSE_POPUP,
        showFreqResponsePopup: true,
      });
    }

    const valueText = roundFloat(square.userData.pressure, parameterDecimals).toString();
    const pressureText = makePressureText(valueText, size, [square.position.x, square.position.y, square.position.z]);

    pressureText.userData.index = square.userData.index;
    pressureText.userData.pointId = square.parent?.userData.pointId;
    const cursorTarget = makeCursorTarget(size, [square.position.x, square.position.y, square.position.z]);

    const textAndTarget = new Group();
    textAndTarget.add(pressureText);
    textAndTarget.add(cursorTarget);

    if (pressureGroup.current) {
      pressureGroup.current.add(textAndTarget);
    }

    pressureTextArray.push(pressureText);

    // Re-render canvas
    invalidate();
  }, true);

  const handleOutsideClick = () => {
    if (pressureGroup.current) {
      pressureGroup.current.children = [];
    }
    pressureTextArray = [];
    dispatch({
      type: ActionType.SET_SELECTED_RECEIVER_SQUARE,
      receiverSquare: null,
    });
    dispatch({
      type: ActionType.SHOW_FREQ_RESPONSE_POPUP,
      showFreqResponsePopup: false,
    });

    // Re-render canvas
    invalidate();
  };

  return colorBar ? (
    <>
      <group {...clickHandlerProps} onPointerMissed={handleOutsideClick} name="heatmaps">
        {gridReceiverResultsToDisplay.map((result) => (
          <Heatmap
            key={`${result.id}`}
            isExporting={isExporting}
            points={result.points}
            result={result}
            colorBar={targetValueColorBar ?? colorBar}
            valuesChosen={
              gridReceiverSubView === 'modal'
                ? modalAnalysisData?.[result.pointId]?.[modalFrequencySelected]
                : gridReceiverSubView === 'grid'
                ? // @ts-expect-error Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'ParameterValues'.
                  parameterValuesForSelectedSource?.[result.pointId]?.[selectedParameter!]?.[
                    selectedParameter !== 'sti' ? selectedFrequency! : selectedNcCurve!
                  ]
                : []
            }
            isInterpolated={isInterpolated}
            isPlayingModalAnalysis={isPlayingModalAnalysis}
            isVisible={
              resultsView === ResultsView.ResultsGridReceiversView &&
              !hiddenSurfaceReceivers.find((surface: GridReceiver) => surface.id === result.pointId)
            }
            decimals={getParameterDecimals(
              gridReceiverSubView === 'modal' ? modalAnalysisSelectedParameter : selectedParameter!
            )}
            targetValue={targetValueColorBar}
          />
        ))}
      </group>

      <group ref={pressureGroup} renderOrder={20}></group>

      {!!gridReceiverResultsToDisplay.length && (
        <ColorScale
          isExporting={isExporting}
          setIsExporting={setIsExporting}
          colorBar={colorBar}
          visible={resultsView === ResultsView.ResultsGridReceiversView}
          selectedFrequency={gridReceiverSubView === 'modal' ? modalFrequencySelected.toString() : selectedFrequency}
          selectedParameter={gridReceiverSubView === 'modal' ? modalAnalysisSelectedParameter : selectedParameter}
          selectedNcCurve={selectedNcCurve}
          onCustomMaxChange={setCustomMax}
          onCustomMinChange={setCustomMin}
          disabled={selectedParameter === 'sti' || selectedParameter === 'd50'}>
          <ColorMapMenu
            colorMap={colorMap}
            setColorMap={setColorMap}
            options={Object.keys(ColorMapKeywords)}
            disabled={selectedParameter === 'sti' || selectedParameter === 'd50'}
          />
        </ColorScale>
      )}
    </>
  ) : null;
};
