import { useEffect } from 'react';

import { useResultComparisonContext } from '../context/ResultComparisonContext';

import { useGetLastSolveResultsBySimulationId } from './';

import { ActionType } from '../constants';

type UseLastSolveResultsProps = {
  hasFetchedSourceSummingResults: boolean;
};

export const useLastSolveResults = ({ hasFetchedSourceSummingResults }: UseLastSolveResultsProps) => {
  const { dispatch, state } = useResultComparisonContext();

  // Make sure we already loaded the last source summing results before we try to fetch the last solve results.
  // Otherwise we might default to the wrong source / receiver selection (if a result preset is selected)
  const { data: lastSolveResults } = useGetLastSolveResultsBySimulationId(
    state.simulationId,
    hasFetchedSourceSummingResults
  );

  // Dispatch an action to set the lastSolveResults when we get new solve result
  // or when we switch back from grid receiver view
  useEffect(() => {
    if (lastSolveResults?.sourceResults.length) {
      dispatch({
        type: ActionType.UPDATE_SOLVE_RESULTS,
        payload: lastSolveResults,
      });
    }
  }, [lastSolveResults]);
};
