import { useQuery } from '@tanstack/react-query';

import { GetSubscriptionResponse } from './useGetSubscription';

import { config } from '@/__config__/config';

import axios from '@/axios';

const { adminApiUrl } = config;

const getSubscriptions = async (organizationId: string): Promise<GetSubscriptionResponse> => {
  const { data } = await axios.post<GetSubscriptionResponse>(
    `/api/Subscription/GetSubscription`,
    {
      organizationId,
    },
    {
      baseURL: adminApiUrl,
    }
  );

  return data;
};

export const useGetSubscriptionsForOrganization = (organizationId: string) => {
  return useQuery<GetSubscriptionResponse>(['subscriptions', organizationId], () => getSubscriptions(organizationId), {
    enabled: organizationId.length > 0,
    refetchOnWindowFocus: false,
  });
};
