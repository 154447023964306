import { Fragment, useEffect, useState } from 'react';

import { Slider, SliderThumb, styled } from '@mui/material';

import styles from './styles.module.scss';

interface FrequencySliderSelectorProps {
  freqValue: number;
  setFreqValue: (freqValue: number) => void;
  min: number;
  max: number;
  disabled: boolean;
}

const ALL_MARKS = Array.from({ length: 33 }, (_, i) => i + 1);
const BIG_INDEXES = [0, 4, 8, 12, 16, 20, 24, 28, 32];

export const FrequencySliderSelector = ({
  freqValue,
  setFreqValue,
  min,
  max,
  disabled,
}: FrequencySliderSelectorProps) => {
  const [scaledIndex, setScaledIndex] = useState<number>(0);

  const handleChange = (_: Event, newValue: number | number[]) => {
    setFreqValue(newValue as number);
  };

  useEffect(() => {
    const mappedNumber = mapRange(freqValue, min, max, 0, ALL_MARKS.length);

    setScaledIndex(mappedNumber);
  }, [freqValue, min, max]);

  const mapRange = (inputValue: number, inputMin: number, inputMax: number, outputMin: number, outputMax: number) => {
    return ((inputValue - inputMin) / (inputMax - inputMin)) * (outputMax - outputMin) + outputMin;
  };

  return (
    <div className={styles['freq-slider-selector']}>
      <CustomSlider
        disabled={disabled}
        value={freqValue}
        aria-label="Frequency slider selector"
        valueLabelDisplay="off"
        slots={{ thumb: CustomThumbComponent }}
        onChange={handleChange}
        min={min}
        max={max}
      />
      <div className={styles['freq-slider-marks']}>
        {ALL_MARKS.map((markNumber, index) => (
          <Fragment key={markNumber}>
            {BIG_INDEXES.includes(index) ? (
              <span
                className={`${styles['big-mark']} ${
                  index === Math.floor(scaledIndex) ||
                  (index === BIG_INDEXES[BIG_INDEXES.length - 1] && scaledIndex === ALL_MARKS.length)
                    ? styles['selected']
                    : ''
                }`}></span>
            ) : (
              <span
                className={`${styles['small-mark']} ${
                  index === Math.floor(scaledIndex) ? styles['selected'] : ''
                }`}></span>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

interface CustomThumbComponentProps extends React.HTMLAttributes<unknown> {}

const CustomThumbComponent = (props: CustomThumbComponentProps) => {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      {Array.from({ length: 3 }).map((_, index) => (
        <span key={index} className="custom-bar" />
      ))}
    </SliderThumb>
  );
};

const CustomSlider = styled(Slider)({
  color: '#00F5BA',
  height: 4,
  '& .MuiSlider-track': {
    border: '1px solid #171717',
  },
  '& .MuiSlider-rail': {
    color: '#171717',
  },
  '& .MuiSlider-thumb': {
    height: 15,
    width: 15,
    backgroundColor: '#F5F5F5',
    '&:hover': {
      boxShadow: '0 0 0 3px rgb(73 74 74 / 16%)',
    },
    '& .custom-bar': {
      height: 9,
      width: 1,
      backgroundColor: '#A5A5A53D',
      margin: '0 1px',
    },
  },
  '& .MuiSlider-dragging': {
    boxShadow: '0 0 0 3px rgb(73 74 74 / 16%)',
  },
});
