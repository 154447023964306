import { datadogRum } from '@datadog/browser-rum';

import { ActionType, useCreateMaterialContext } from '../context/CreateMaterialContext';

import { FileUpload } from '@/components/Shared/FileUpload';
import { TrblUploadIcon } from '@/components/Icons';

import { VALID_FILE_TYPES } from '../constants';

import styles from '../styles.module.scss';

type MaterialFileUploadProps = {
  errorMessage: null | string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
  disabled?: boolean;
  simple?: boolean;
};

export const MaterialFileUpload: React.FC<MaterialFileUploadProps> = ({
  errorMessage,
  setErrorMessage,
  disabled,
  simple,
}) => {
  const { upload, dispatch } = useCreateMaterialContext();

  const onChangeFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const file = Array.from(event.currentTarget.files)[0];
      parseFile(file);
    }
  };

  const onDropFileUpload = (event: React.DragEvent<HTMLElement>) => {
    if (event.dataTransfer.files) {
      const file = Array.from(event.dataTransfer.files)[0];
      parseFile(file);
    }
  };

  const parseFile = (file: File) => {
    if (VALID_FILE_TYPES.includes(file?.type)) {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        const contents = event.target?.result;
        dispatch({
          type: ActionType.SET_UPLOADED_FILE,
          upload: { file: file, contents },
        });
      };

      reader.readAsText(file);
    } else {
      datadogRum.addError(`Invalid file type: ${file?.type}`);
      setErrorMessage(`Invalid file type: ${file?.type}`);
    }
  };

  return simple ? (
    <label className={styles.upload_button} style={disabled ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
      <TrblUploadIcon />
      Upload file
      <input
        onChange={onChangeFileUpload}
        accept={'.csv, .txt'}
        id="file-upload"
        type="file"
        disabled={disabled}
        style={{ display: 'none' }}
      />
    </label>
  ) : (
    <div style={{ marginTop: '8px', height: '100px' }}>
      <FileUpload
        small
        label="Drop file here to upload"
        disabled={disabled}
        errorMessage={errorMessage}
        accept={'.csv, .txt'}
        acceptText=".csv or .txt files"
        onChange={onChangeFileUpload}
        onDropFile={onDropFileUpload}
        shouldReset={upload === null}
      />
    </div>
  );
};
