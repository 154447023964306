import { FC, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import { AccountProfile } from '../AccountProfile';
import { SidepanelWidth } from '../PageLayout';

import './styles.scss';

import trebleLogoUrl from '@/images/treble-logo.png';

type HeaderProps = {
  hasSidepanel: boolean;
  sidepanelWidth: SidepanelWidth;
  mainContent?: ReactNode;
  sideContent?: ReactNode;
  mainContentMinWidth?: string;
};

export const Header: FC<HeaderProps> = ({
  hasSidepanel,
  mainContent,
  sideContent,
  sidepanelWidth,
  mainContentMinWidth,
}) => {
  const location = useLocation();

  const mainContentClassname = location.pathname.includes('/editor')
    ? 'header-content-main gray'
    : 'header-content-main dark';

  const sideContentClassname = location.pathname.includes('/editor')
    ? 'header-content-side gray'
    : location.pathname === '/' || location.pathname.includes('space')
    ? 'header-content-side light-gray'
    : 'header-content-side dark';

  return (
    <header
      className={`header-container ${
        hasSidepanel && !sidepanelWidth.isFixed ? `sidepanel-${sidepanelWidth.size}` : ''
      }`}>
      <div
        style={{
          minWidth: mainContentMinWidth,
          width: sidepanelWidth.isFixed ? `calc(100% - ${sidepanelWidth.size})` : undefined,
        }}
        className={mainContentClassname}>
        {location.pathname === '/' ? (
          <a href="/">
            <img src={trebleLogoUrl} alt="Logo" />
          </a>
        ) : (
          <Link to="/">
            <img src={trebleLogoUrl} alt="Logo" />
          </Link>
        )}

        <div style={{ flex: '1 1 auto' }}>{mainContent}</div>
      </div>
      <div
        className={sideContentClassname}
        style={sidepanelWidth?.isFixed ? { width: sidepanelWidth.size } : undefined}>
        <Box component="div" alignItems={'center'} flex="1 1 0">
          {sideContent}
        </Box>
        <Box component="div" display="flex" alignItems={'center'} paddingRight={'9px'}>
          <AccountProfile />
        </Box>
      </div>
    </header>
  );
};
