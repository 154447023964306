import { FC, SyntheticEvent } from 'react';

import { TrblTooltip } from '@/components/Shared';
import { TrblAddMemberIcon } from '@/components/Icons';

import styles from './styles.module.scss';

type ShareProjectButtonProps = {
  size?: string;
  onClick: (e: SyntheticEvent) => void;
};

export const ShareProjectButton: FC<ShareProjectButtonProps> = ({ size = '26', onClick }) => {
  return (
    <button className={styles['members-button']} onClick={onClick}>
      <TrblTooltip title="Share project">
        <span>
          <TrblAddMemberIcon width={size} height={size} fill="none" />
        </span>
      </TrblTooltip>
    </button>
  );
};
