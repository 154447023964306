import { IconProps } from '@/types';

export const TrblWarningIcon = ({ fill = '#FFBB6F', width = '12', height = '12' }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5593 10L6.88584 1.07502C6.7132 0.745592 6.37201 0.539185 6.00009 0.539185C5.62817 0.539185 5.28697 0.745592 5.11434 1.07502L0.440337 10C0.277954 10.31 0.289448 10.6824 0.470645 10.9818C0.651842 11.2812 0.976373 11.4641 1.32634 11.464H10.6733C11.0233 11.4641 11.3478 11.2812 11.529 10.9818C11.7102 10.6824 11.7217 10.31 11.5593 10ZM5.4 4.21155C5.4 3.93541 5.66191 3.71155 5.985 3.71155C6.30809 3.71155 6.57 3.93541 6.57 4.21155V7.21155C6.57 7.48769 6.30809 7.71155 5.985 7.71155C5.66191 7.71155 5.4 7.48769 5.4 7.21155V4.21155ZM6.01084 9.96652H6.02484C6.22339 9.96331 6.41225 9.88017 6.54871 9.7359C6.68516 9.59164 6.75767 9.39844 6.74984 9.20002C6.73374 8.79069 6.39748 8.46701 5.98784 8.46652H5.97384C5.77578 8.47015 5.58749 8.55324 5.45133 8.69711C5.31516 8.84099 5.24256 9.03356 5.24984 9.23152C5.26539 9.64094 5.60113 9.96521 6.01084 9.96652Z"
      fill={fill}
    />
  </svg>
);
