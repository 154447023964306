import { useEffect, useState } from 'react';
import Gradient from 'javascript-color-gradient';
import { Data } from 'plotly.js';

import { gradientEndColors } from '../../constants';
import { FREQUENCY_VALUES } from './constants';

import { convertPressureBasedImpulseResponseToDb, sortData } from './utils';
import { toSorted } from '@/utils/trebleFunctions';

import { ResultComparison } from '../../types';
import { ParsedResponseData, PlotType, ResultType } from './types';

export const useResponsePlot = (
  responseData: ParsedResponseData[],
  selectedPlotType: PlotType,
  frequencyFilter: string[],
  comparisons: ResultComparison[]
) => {
  const [plotlyData, setPlotlyData] = useState<Data[]>([]);

  // If the parsed data changes or the selected plot type
  useEffect(() => {
    if (selectedPlotType !== 'Spatial decay' && responseData.length > 0) {
      // Get all the receiver ids that are selected for each comparison
      const selectedReceiverIds = comparisons.flatMap((comparison) => {
        return comparison.formState?.simulationData?.selectedReceiverObjects?.map(
          (receiverObject) => receiverObject.id
        );
      });

      // Filter the data by the selected receivers so we are not showing all the receivers
      // for each simulation even though they have been previously fetched
      const filteredDataByReceiverIds = responseData.filter((data) => selectedReceiverIds.includes(data.receiverId));

      // Filter by result type
      let filteredPlotData = filteredDataByReceiverIds.filter(
        (data) => data.resultType === ResultType[selectedPlotType]
      );

      // Filter by frequency
      if (selectedPlotType === 'EDC' && frequencyFilter.length) {
        filteredPlotData = filteredPlotData.filter((x) => frequencyFilter.includes(x.frequency.toString()));
      } else if (selectedPlotType === 'EDC' && frequencyFilter.length === 0) {
        filteredPlotData = [];
      }

      const orderedData = toSorted(filteredPlotData, sortData);

      const toPlotlyData: Data[] = [];

      // Loop through the comparisons and add the data to the plotly data array
      comparisons.forEach((comparison) => {
        // @ts-expect-error this does exist
        if (comparison.formState?.simulationData?.selectedReceiverObjects?.length > 1) return;

        // Find the appropriate data to display based on the selected receiver
        const dataToDisplay = orderedData.filter(
          (x) => x.receiverId === comparison.formState?.simulationData?.selectedReceiverObjects?.[0]?.id
        );
        if (dataToDisplay) {
          // Loop though each frequency that is selected
          dataToDisplay.forEach((data) => {
            const index = FREQUENCY_VALUES.findIndex((freq) => freq === data.frequency);
            const colorGradients = new Gradient()
              .setColorGradient(gradientEndColors[comparison.color][0], gradientEndColors[comparison.color][1])
              .setMidpoint(10)
              .getColors();
            let yData = data.y;
            if (selectedPlotType === 'Impulse response dB') {
              yData = convertPressureBasedImpulseResponseToDb(yData);
            }
            toPlotlyData.push({
              marker: {
                color:
                  data.resultType === 'edc'
                    ? index >= 0
                      ? colorGradients[index < 2 ? index + 1 * index : index + 2]
                      : comparison.color
                    : comparison.color,
              },
              y: yData,
              x: data.x,
              type: data.type,
              mode: data.mode,
              name: comparison?.formState?.title,
            });
          });
        } else return [] as Data;
      });

      setPlotlyData(toPlotlyData);
    } else {
      setPlotlyData([]);
    }
  }, [responseData, selectedPlotType, frequencyFilter]);

  return plotlyData;
};
