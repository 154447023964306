import KDBush from 'kdbush';
import {
  BufferAttribute,
  BufferGeometry,
  DoubleSide,
  Float32BufferAttribute,
  ShaderMaterial,
  Vector2,
  Vector3,
} from 'three';

import { createGridPlane } from '../../GridReceivers';

// @ts-expect-error Could not find a declaration file for module '@/utils/ColorBar'.
import { ColorBar } from '@/utils/ColorBar';
import { roundFloat } from '@/utils/trebleFunctions';

import { GridReceiverPoint, GridReceiverResultsDto, GridReceiverUserInput } from '@/types';

export const createInterpolatedHeatmap = (
  colorBar: ColorBar,
  result: GridReceiverResultsDto,
  points: GridReceiverPoint[],
  parameterValues: number[],
  isVisible: boolean,
  decimals: number,
  isInterpolated: boolean,
  targetValue?: boolean,
  makeZeroTransparent?: boolean
) => {
  if (isInterpolated && parameterValues.length && points.length) {
    const start = Date.now();

    const resultPoints = points ?? result.points;
    const userInput = result.userInput;

    // We create a finer mesh for a more smooth visualization of the interpolation.
    // The performance is highly dependant on the number of result points,
    // so we dynamically adjust that to get a good performance/visual compromise
    const subGridScaling = getSubGridScaling(resultPoints.length);

    const subGridCoarseness = userInput.coarseness / subGridScaling;
    const plane = createGridPlane(userInput, true, subGridCoarseness);

    // We use the untransformed plane so we can do operations that assume it is aligned with the xyz axes in the bilinear interpolation
    const untransformedPlane = createGridPlane(userInput, true, subGridCoarseness, false);
    const localGridPointLocationByGridIndex = getUntransformedGridPointsByGridIndex(
      untransformedPlane.geometry,
      userInput,
      subGridScaling
    );

    plane.material = getGridPlaneMaterial();
    plane.material.visible = isVisible;
    plane.renderOrder = 10;

    initGeometryAttributes(plane.geometry);

    const colors = plane.geometry.getAttribute('color') as BufferAttribute;
    const alphas = plane.geometry.getAttribute('alpha') as BufferAttribute;

    const verticesLocal: VertexInfo[] = getVerticesInfo(
      untransformedPlane.geometry,
      result,
      parameterValues,
      subGridScaling
    );

    const validResultPoints: GridReceiverPointInfo[] = [];
    resultPoints.forEach((result, index) => {
      if (isValidParameterValue(parameterValues[index])) {
        const pos = localGridPointLocationByGridIndex.get(result.gridIndex);
        if (pos) {
          const resultPointInfo = new GridReceiverPointInfo(index, result, pos);
          validResultPoints.push(resultPointInfo);
        }
      }
    });

    // Create a 2d spatial index for the result points to increase performance
    const validResultPointIndex = getResultPointIndex(validResultPoints);

    verticesLocal.forEach(function (vertex) {
      let alpha = 0;
      if (!vertex.isHidden) {
        const valuesWithPosition: ValuePositionInfo[] = [];

        // find result points in a +- tile width bounding box
        const resultPointIndicesInRange = validResultPointIndex.range(
          vertex.position.x - userInput.coarseness,
          vertex.position.y - userInput.coarseness,
          vertex.position.x + userInput.coarseness,
          vertex.position.y + userInput.coarseness
        );
        const validResultsInRange = resultPointIndicesInRange.map((i) => validResultPoints[i]);
        validResultsInRange.forEach((result) => {
          const distance = result.localPosition.distanceTo(vertex.position);
          const xOffset = result.localPosition.x - vertex.position.x;
          const yOffset = result.localPosition.y - vertex.position.y;
          // Limit to values no more than one tile width/height away
          if (Math.abs(xOffset) < userInput.coarseness && Math.abs(yOffset) < userInput.coarseness) {
            const value = parameterValues[result.gridIndex];
            const valueInfo = new ValuePositionInfo(value, distance, result.localPosition);

            valuesWithPosition.push(valueInfo);
          }
        });

        const hasValues = valuesWithPosition.length > 0;
        alpha = hasValues ? 1 : 0;

        // Interpolate values if any
        if (hasValues) {
          const vertexParameterValue = interpolateValuesBilinear(vertex.position, valuesWithPosition);

          if (vertexParameterValue === 0 && makeZeroTransparent) {
            alpha = 0;
          } else {
            const color = colorBar.getColor(vertexParameterValue);

            // if targetValue heatmap then check if value is within min and max of colorBar
            if (targetValue) {
              const value = roundFloat(vertexParameterValue, decimals);
              if (!(value <= colorBar.getMax() && value >= colorBar.getMin())) {
                alpha = 0;
              }
            }

            colors.setXYZ(vertex.index, color.r, color.g, color.b);
          }
        }
      }

      alphas.setX(vertex.index, alpha);
    });

    fixIncompleteTrianglesFadeout(plane.geometry);

    const end = Date.now();
    console.log(`createInterpolatedHeatmap Execution time: ${end - start} ms`);
    return plane;
  }

  return null;
};

function getResultPointIndex(results: GridReceiverPointInfo[]): KDBush {
  // initialize KD tree for N valid result points
  const resultPointIndex = new KDBush(results.length);

  // fill in positions
  results.forEach((result) => {
    resultPointIndex.add(result.localPosition.x, result.localPosition.y);
  });

  // perform the indexing
  resultPointIndex.finish();

  return resultPointIndex;
}

function initGeometryAttributes(geometry: BufferGeometry) {
  const cols = [];
  const alphas = [];
  const verts = geometry.getAttribute('position');

  for (let k = 0; k < verts.count; k++) {
    cols.push(1, 1, 1);
    alphas.push(1);
  }

  geometry.setAttribute('color', new Float32BufferAttribute(cols, 3));
  geometry.setAttribute('alpha', new Float32BufferAttribute(alphas, 1));
}

/**
    @param resultCount number of result points in the grid
    @returns scaling factor based on the square root of the number of receivers (as the computational complexity is the scaling squared)
  **/
function getSubGridScaling(resultCount: number) {
  // Chosen after small sample empirical testing
  const maxSquareSizeThreshold = 50; // 2500 grid receivers
  const minSquareSizeThreshold = 4; // 16 grid receivers

  const squareSizeSpan = maxSquareSizeThreshold - minSquareSizeThreshold;

  const maxScaling = 20;
  const minScaling = 2;

  const scalingSpan = maxScaling - minScaling;

  const scalingIncrementPerSquareSizeStep = scalingSpan / squareSizeSpan; //how much to decrease the scaling per square

  const squareSize = clamp(Math.sqrt(resultCount), minSquareSizeThreshold, maxSquareSizeThreshold);
  let scaling = Math.round(
    (maxSquareSizeThreshold - squareSize - minSquareSizeThreshold) * scalingIncrementPerSquareSizeStep
  );
  if (scaling % 2 != 0) {
    //Should be an even number to best align with the receiver position (then we have a vertex aligning perfectly with the receiver).
    scaling -= 1;
  }
  return Math.min(Math.max(scaling, minScaling), maxScaling);
}

/**
 * clamps a value between the min and max values
 * @param value the value to clamp
 * @param min the min value cutoff
 * @param max the max value cutoff
 * @returns
 */
function clamp(value: number, min: number, max: number): number {
  return Math.max(Math.min(value, max), min);
}

/**
 * This approach is a bit stupid and overly complex, but functions!
 *
 * Finds the center position of the grid point in local coordinates in the untransformed plane
 *
 * Should be rewritten so we can just transform the result point into the local domain - which would be way more efficient.
 **/
function getUntransformedGridPointsByGridIndex(
  geometry: BufferGeometry,
  userInput: GridReceiverUserInput,
  subGridScaling: number
): Map<number, Vector3> {
  const gridTileMinMaxVertices: Map<number, [number, number]> = new Map();
  const positions = geometry.getAttribute('position') as BufferAttribute;
  if (geometry.index) {
    for (let i = 0; i < geometry.index.array.length; i += 3) {
      const triangleIndex = i / 3;
      const gridIndex = gridIndexFromTriangleIndex(triangleIndex, userInput, subGridScaling);
      const gridTileMax = gridTileMinMaxVertices.get(gridIndex);
      if (!gridTileMax) {
        const min = geometry.index.array[i + 1]; //the second index is in the upper left corner of the triangle (when first triangle in grid)
        gridTileMinMaxVertices.set(gridIndex, [min, min]);
      } else {
        const max = geometry.index.array[i + 1]; //the second index is in the lower right corner of the triangle (when last triangle in grid)
        gridTileMax[1] = max;
      }
    }
  }
  const localGridPositionByGridIndex: Map<number, Vector3> = new Map();
  gridTileMinMaxVertices.forEach((gridTileMinMax, gridIndex) => {
    const minVertex = gridTileMinMax[0];
    const maxVertex = gridTileMinMax[1];
    const min = new Vector3(positions.getX(minVertex), positions.getY(minVertex), positions.getZ(minVertex));
    const max = new Vector3(positions.getX(maxVertex), positions.getY(maxVertex), positions.getZ(maxVertex));
    const center = min.add(max).multiplyScalar(0.5); //Center is the avg position of the minmax vertices
    localGridPositionByGridIndex.set(gridIndex, center);
  });

  return localGridPositionByGridIndex;
}

/**
 * @see {@link https://en.wikipedia.org/wiki/Bilinear_interpolation}
 * @param vertexPosition position of the vertex in the plane's local coordinates
 * @param interpolationValues
 * @returns interpolated value
 */

function interpolateValuesBilinear(vertexPosition: Vector3, interpolationValues: ValuePositionInfo[]): number {
  let nominator = 0;
  let denominator = 0;

  let hasAnyXDistZero: boolean = false;
  let hasAnyYDistZero: boolean = false;
  interpolationValues.forEach((value) => {
    if (value.position.x - vertexPosition.x == 0) {
      hasAnyXDistZero = true;
    }
    if (value.position.y - vertexPosition.y == 0) {
      hasAnyYDistZero = true;
    }
  });
  for (let i = 0; i < interpolationValues.length; i++) {
    const valuePositionInfo = interpolationValues[i];
    if (valuePositionInfo.distance == 0) {
      return valuePositionInfo.value;
    }

    const xDist = valuePositionInfo.position.x - vertexPosition.x;
    //only consider points with x dist zero if any values with zero x dist
    if (hasAnyXDistZero && xDist != 0) {
      continue;
    }
    const yDist = valuePositionInfo.position.y - vertexPosition.y;
    //only consider points with y dist zero if any values with zero y dist
    if (hasAnyYDistZero && yDist != 0) {
      continue;
    }
    //If we have values that align perfectly with the x/y component the area will be zero. Instead we only consider the non-zero component
    const xComponent = hasAnyXDistZero ? 1 : Math.abs(valuePositionInfo.position.x - vertexPosition.x);
    const yComponent = hasAnyYDistZero ? 1 : Math.abs(valuePositionInfo.position.y - vertexPosition.y);
    const area = xComponent * yComponent;
    if (area == 0) {
      return valuePositionInfo.value;
    }
    nominator += valuePositionInfo.value / area;
    denominator += 1.0 / area;
  }
  return nominator / denominator;
}

/**
 * Only finite values within plus-minus 150 are considered valid. The 150 value is chosen by the core team as this should encompass all valid parameter values in dB and seconds
 * @param value parameter value to validate
 * @returns true if value is valid, false otherwise.
 */
function isValidParameterValue(value: number) {
  return isFinite(value) && Math.abs(value) < 150;
}

function getVerticesInfo(
  geometry: BufferGeometry,
  result: GridReceiverResultsDto,
  parameterValues: number[],
  subGridScaling: number
): VertexInfo[] {
  const vertices: VertexInfo[] = [];
  const addedVertices: Set<number> = new Set<number>();
  const gridIndicesWithValidResults: Set<number> = new Set(
    result.points
      .filter((point, index) => {
        return isValidParameterValue(parameterValues[index]); // parameterValues and result.points are same size and have a 1:1 mapping
      })
      .map((x) => x.gridIndex)
  );
  const positions = geometry.getAttribute('position') as BufferAttribute;

  if (geometry.index) {
    //each 3 subsequent values from the index array make up vertex indices for a triangle in the plane mesh
    for (let i = 0; i < geometry.index.array.length; i += 3) {
      const triangleIndex = i / 3;
      const gridIndex = gridIndexFromTriangleIndex(triangleIndex, result.userInput, subGridScaling);

      //First filter out the vertices that are part of a grid tile with results
      if (gridIndicesWithValidResults.has(gridIndex)) {
        const triangleVertices: number[] = [];
        triangleVertices.push(geometry.index.array[i]);
        triangleVertices.push(geometry.index.array[i + 1]);
        triangleVertices.push(geometry.index.array[i + 2]);
        triangleVertices.forEach((vertex) => {
          if (!addedVertices.has(vertex)) {
            addedVertices.add(vertex);
            const position = new Vector3(positions.getX(vertex), positions.getY(vertex), positions.getZ(vertex));
            vertices.push(new VertexInfo(vertex, position, false));
          }
        });
      }
    }
    // As each of the vertices can be a part of multiple triangles we can't be certain what vertices to hide, until we have populated all the ones with results
    for (let vertex = 0; vertex < positions.array.length; vertex++) {
      //If already added, it should not be hidden as it is a part of a triangle with results
      if (!addedVertices.has(vertex)) {
        const position = new Vector3(positions.getX(vertex), positions.getY(vertex), positions.getZ(vertex));
        vertices.push(new VertexInfo(vertex, position, true));
      }
    }
  }
  return vertices;
}

/**
   * Returns the grid index (same as in the grid results) of the grid tile this triangle is a part of
   * 
   * Visual representation of grid and triangle indices:
    
    ![](grid_receivers_diagram.png)
  */
function gridIndexFromTriangleIndex(
  triangleIndex: number,
  userInput: GridReceiverUserInput,
  subGridScaling: number
): number {
  // - The underlying mesh of the grid is subdivided into triangles that are indexed from upper left to lower right corner (as reading text)
  // - Grid refers to the grid as defined by the user measured in tiles (with a grid receiver in the center)
  // - Subdivided grid refers to the finer mesh used for smoother interpolation visualization measured in triangles
  // - Each tile includes subGridScaling * subGridScaling * 2 number of triangles

  const gridColumns = userInput.width / userInput.coarseness; // the number of grid tiles along the width of the grid
  const subGridColumns = gridColumns * 2 * subGridScaling; // number of triangles along the width of the grid (along a single row).
  const subGridRow = Math.floor(triangleIndex / subGridColumns); // the row in the subdivided grid this triangle index is in
  const gridRow = Math.floor(subGridRow / subGridScaling); // the row in the user defined grid
  const subGridColumn = triangleIndex - subGridRow * subGridColumns; //the column in the subdivided grid this triangle index is in
  const gridColumn = Math.floor(subGridColumn / subGridScaling / 2); // the column in the user defined grid
  const gridIndex = gridRow * gridColumns + gridColumn; //indexed from upper left to lower right corner

  return gridIndex;
}

/**
 * Helper function to visualize how the grid indices move along the plane
 **/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function debugColorGridTiles(
  geometry: BufferGeometry,
  result: GridReceiverResultsDto,
  subGridScaling: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  colorBar: any
) {
  const gridColumns = Math.round(result.userInput.width / result.userInput.coarseness);
  const gridRows = Math.round(result.userInput.length / result.userInput.coarseness);
  const gridSize = gridColumns * gridRows;

  const colors = geometry.getAttribute('color') as BufferAttribute;
  const valueMap = linearlySpaceNValues(gridSize, colorBar.minV, colorBar.maxV);
  if (geometry.index) {
    for (let i = 0; i < geometry.index.array.length; i += 3) {
      const vertex1 = geometry.index.array[i];
      const vertex2 = geometry.index.array[i + 1];
      const vertex3 = geometry.index.array[i + 2];

      const gridIndex = gridIndexFromTriangleIndex(i / 3, result.userInput, subGridScaling);
      const value = valueMap[gridIndex];
      const color = colorBar.getColor(value);
      colors.setXYZ(vertex1, color.r, color.g, color.b);
      colors.setXYZ(vertex2, color.r, color.g, color.b);
      colors.setXYZ(vertex3, color.r, color.g, color.b);
    }
  }
}

function getGridPlaneMaterial(): ShaderMaterial {
  return new ShaderMaterial({
    uniforms: {
      u_time: { value: 0 },
      u_resocolorBarion: { value: new Vector2() },
    },
    // @ts-expect-error Object is possibly 'null'.
    vertexShader: document.getElementById('vertexShader').textContent,
    // @ts-expect-error Object is possibly 'null'.
    fragmentShader: document.getElementById('fragmentShader').textContent,
    side: DoubleSide,
    transparent: true,
    // wireframe: true
  });
}

/**
 *
 * @param n number of value bins
 * @param min minimum value
 * @param max maximum value
 * @returns n linearly spaced values ranging from min to max
 */
function linearlySpaceNValues(n: number, min: number = 0, max: number = 1): number[] {
  const increment = (max - min) / n;
  let value = min;
  const values: number[] = [];
  for (let i = 0; i < n; i++) {
    values.push(value);
    value += increment;
  }
  return values;
}

/**
 * Colors incomplete triangles (with some vertices with alpha = 0) using the average color of the rest of the triangle to give a better looking fadeout
 **/
function fixIncompleteTrianglesFadeout(geometry: BufferGeometry): void {
  const alphas = geometry.getAttribute('alpha') as BufferAttribute;
  const colors = geometry.getAttribute('color') as BufferAttribute;
  const verticesIndices: Set<number> = new Set<number>();
  const index = geometry.index;
  const hiddenVertexIndices: Set<number> = new Set<number>();
  if (index) {
    // Populate all vertex indices
    for (let i = 0; i < index.array.length; i++) {
      verticesIndices.add(index.array[i]);
    }

    // Find hidden vertices
    verticesIndices.forEach((vertexIndex) => {
      if (alphas.getX(vertexIndex) == 0) {
        hiddenVertexIndices.add(vertexIndex);
      }
    });

    // Identify incomplete triangles
    for (let i = 0; i < index.array.length; i += 3) {
      const tr1 = index.array[i];
      const tr2 = index.array[i + 1];
      const tr3 = index.array[i + 2];
      const hidden: boolean[] = [];
      hidden.push(hiddenVertexIndices.has(tr1));
      hidden.push(hiddenVertexIndices.has(tr2));
      hidden.push(hiddenVertexIndices.has(tr3));

      // Color zero alpha vertices with with avg color in triangles that are partially complete
      if (hidden.filter((x) => x == true).length < 3) {
        const triangleColorInfo: VertexColorInfo[] = [];
        triangleColorInfo.push(getVertexColorInfo(tr1, hiddenVertexIndices, colors));
        triangleColorInfo.push(getVertexColorInfo(tr2, hiddenVertexIndices, colors));
        triangleColorInfo.push(getVertexColorInfo(tr3, hiddenVertexIndices, colors));

        const colorSum = new Vector3();
        let colorCount = 0;
        triangleColorInfo
          .filter((colorInfo) => colorInfo.isHidden == false)
          .forEach((colorInfo) => {
            colorSum.add(colorInfo.color);
            colorCount++;
          });
        const avgColor = colorSum.multiplyScalar(1 / colorCount);
        triangleColorInfo
          .filter((colorInfo) => colorInfo.isHidden)
          .forEach((colorInfo) => {
            colors.setXYZ(colorInfo.vertexIndex, avgColor.x, avgColor.y, avgColor.z);
          });
      }
    }
  }
}

function getVertexColorInfo(
  vertexIndex: number,
  hiddenVertexIndices: Set<number> = new Set<number>(),
  colors: BufferAttribute
): VertexColorInfo {
  return new VertexColorInfo(
    vertexIndex,
    new Vector3().fromBufferAttribute(colors, vertexIndex),
    hiddenVertexIndices.has(vertexIndex)
  );
}

//#region internal classes

class VertexColorInfo {
  vertexIndex: number;
  color: Vector3;
  isHidden: boolean;
  constructor(vertexIndex: number, color: Vector3, isHidden: boolean) {
    this.vertexIndex = vertexIndex;
    this.color = color;
    this.isHidden = isHidden;
  }
}

class VertexInfo {
  index: number;
  position: Vector3;
  isHidden: boolean;
  constructor(vertexIndex: number, position: Vector3, isHidden: boolean = false) {
    this.index = vertexIndex;
    this.position = position;
    this.isHidden = isHidden;
  }
}

class GridReceiverPointInfo {
  gridIndex: number;
  gridReceiverPoint: GridReceiverPoint;
  localPosition: Vector3;
  constructor(gridIndex: number, gridReceiverPoint: GridReceiverPoint, localPosition: Vector3) {
    this.gridIndex = gridIndex;
    this.gridReceiverPoint = gridReceiverPoint;
    this.localPosition = localPosition;
  }
}

class ValuePositionInfo {
  value: number;
  position: Vector3;
  distance: number;
  constructor(value: number, distance: number, position: Vector3) {
    this.value = value;
    this.distance = distance;
    this.position = position;
  }
}
