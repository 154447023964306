import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { useBaseContext } from '@/context/BaseContext';

import { Stack } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { CardsHeader } from '../CardsHeader/CardsHeader';
import { PageHeader } from '../PageHeader/PageHeader';
import { PageLayout } from '../PageLayout';
import { SpaceCard } from '../SpaceCard/SpaceCard';
import { ProjectInfoSidepanel } from './ProjectInfoSidepanel';

import { useGetProjectById } from '@/hooks';

import { SpaceDashboardDto } from '@/types';

import classes from './styles.module.scss';

export const ProjectPage = () => {
  const queryClient = useQueryClient();
  const {
    state: { userInfo },
  } = useBaseContext();
  const params = useParams();
  const projectId = params.id as string;

  const { data: project } = useGetProjectById(projectId || '');

  const [sortedSpaces, setSortedSpaces] = useState<SpaceDashboardDto[]>([]);
  const [sortedAsc, setSortedAsc] = useState(false);
  const [sortedType, setSortedType] = useState('modified');

  const [searchString] = useState('');
  const [spaceSearchString] = useState('');

  const filterSpace = (name: string) => {
    // if no search string or there is a search string and it's included in the space name, then show the space
    if (searchString == '' || name.toLowerCase().includes(searchString.toLowerCase())) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    // set sortedSpaces in correct order
    orderSpacesBy(sortedType, sortedAsc);
  }, [project]);

  const orderSpacesBy = (type: string, asc: boolean) => {
    if (project?.spaces) {
      const spaces = sortedSpaces.length ? sortedSpaces : [...project.spaces];
      if (type !== sortedType) {
        setSortedType(type);
        asc = false;
      }
      if (type == 'modified') {
        if (asc) setSortedSpaces(spaces.sort((a, b) => (a.updatedAt < b.updatedAt ? -1 : 1)));
        else setSortedSpaces(spaces.sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1)));
      }
      if (type == 'name') {
        if (asc) setSortedSpaces(spaces.sort((a, b) => b.name.localeCompare(a.name)));
        else setSortedSpaces(spaces.sort((a, b) => a.name.localeCompare(b.name)));
      }
      setSortedAsc(asc);
    }
  };

  const onSpaceUpdate = () => {
    queryClient.invalidateQueries(['projectById', projectId]);
  };

  return (
    <PageLayout
      navpanel
      sidepanel={project && userInfo && <ProjectInfoSidepanel project={project} loggedInUserId={userInfo?.id} />}>
      {project?.spaces && (
        <div id={project.id} className={`${classes.project_page_content}`}>
          <PageHeader showSwitcher={false} />
          <CardsHeader>
            <Stack width="calc(20vw + 90px)" marginLeft="20px">
              <TrblTooltip title="Sort by name" disableInteractive>
                <button onClick={() => orderSpacesBy('name', !sortedAsc)} style={{ width: 'fit-content' }}>
                  Spaces {sortedType == 'name' ? (sortedAsc ? '↑' : '↓') : ''}
                </button>
              </TrblTooltip>
            </Stack>

            <Stack
              width="100%"
              flexDirection="row"
              justifyContent="space-between"
              gap="8px"
              padding="0 132px 0 0"
              maxWidth="calc(30vw + 65px)">
              <span style={{ width: '110px' }}></span>
              <TrblTooltip title="Sort by modified" disableInteractive>
                <button style={{ width: '112px' }} onClick={() => orderSpacesBy('modified', !sortedAsc)}>
                  Modified {sortedType == 'modified' ? (sortedAsc ? '↑' : '↓') : ''}
                </button>
              </TrblTooltip>
            </Stack>
          </CardsHeader>
          <div className={classes.project_list_container}>
            {sortedSpaces
              .filter((space) => filterSpace(space.name))
              .map((space) => (
                <SpaceCard
                  key={space.id}
                  space={space}
                  projectSearchString={spaceSearchString}
                  onUpdate={onSpaceUpdate}
                />
              ))}
          </div>
        </div>
      )}
    </PageLayout>
  );
};
