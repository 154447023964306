import { useState } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { useBaseContext } from '@/context/BaseContext';

import { ConfirmationDialog } from '@/components/Shared/ConfirmationDialog';
import { EditProjectPopup, InfoProjectPopup } from '@/components/ProjectsView/components';
import { TrblActionsMenu } from '@/components/TrblActionsMenu';

import { useDeleteProject } from '@/hooks';

import { IActions, Info, ProjectAndUsersDto } from '@/types';

export const ProjectActionsMenu = ({
  project,
  setSelected,
  classNames,
}: {
  project: ProjectAndUsersDto;
  setSelected?: (value: boolean) => void;
  classNames?: string;
}) => {
  const {
    state: { userInfo },
  } = useBaseContext();
  const queryClient = useQueryClient();

  const deleteProject = useDeleteProject();

  const [projectDetails, setProjectDetails] = useState<Info>({} as Info);

  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const getProjectActions = (project: ProjectAndUsersDto): IActions[] => {
    return [
      {
        value: 'Edit',
        onClick: () => {
          setShowEditPopup(true);
        },
        key: 'edit-project',
      },
      {
        value: 'Details',
        onClick: () => {
          const creator = project.projectUsers.find((user) => user.id == project.createdBy);
          setProjectDetails({
            id: project.id,
            name: project.name,
            description: project.description,
            createdAt: project.createdAt,
            createdBy: creator?.email,
            updatedAt: project.updatedAt,
            updatedBy: project.updatedByUserEmail,
            elementCount: project.spacesCount,
            projectUsers: project.projectUsers.filter((user) => user.id != project.createdBy),
          });
          setShowDetailsPopup(true);
        },
        key: 'details-project',
      },
      {
        value: 'Delete',
        onClick: () => {
          setShowDeleteDialog(true);
        },
        key: 'delete-project',
        hidden: !userInfo?.roles.includes('Admin') && project.createdBy !== userInfo?.id,
      },
    ];
  };

  const onDeleteProject = (project: ProjectAndUsersDto) => {
    deleteProject.mutate(project.id, {
      onSuccess: () => {
        queryClient.invalidateQueries(['projects']);
        toast.info("'" + project.name + "' deleted");
      },
      onError: () => {
        toast.error('An error occurred while deleting Project');
      },
    });
    handleClosePopup();
  };

  const handleClosePopup = () => {
    setShowEditPopup(false);
    setShowDetailsPopup(false);
    setShowDeleteDialog(false);
    if (setSelected) setSelected(false);
  };

  const handleProjectUpdated = () => {
    handleClosePopup();
    queryClient.invalidateQueries(['projects']);
  };

  return (
    <>
      <TrblActionsMenu
        classNames={classNames}
        actions={getProjectActions(project)}
        id={project.id}
        title="View project actions"
        size="medium"
      />
      {showEditPopup && (
        <EditProjectPopup
          onClose={handleClosePopup}
          onUpdate={handleProjectUpdated}
          id={project.id}
          name={project.name}
          description={project.description}
        />
      )}
      {showDetailsPopup && <InfoProjectPopup info={projectDetails} onClose={handleClosePopup} />}
      {showDeleteDialog && (
        <ConfirmationDialog
          title="Delete project"
          message={() => (
            <span>
              Are you sure you want to delete <b>{project.name}</b> ?
            </span>
          )}
          onConfirm={() => {
            onDeleteProject(project);
          }}
          onCancel={handleClosePopup}
        />
      )}
    </>
  );
};
